import { AgGridReact } from 'ag-grid-react';
import { useFetchingLoader } from '../../store/app/actions';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { getReportTypeReservations } from '../../store/kitchens/actions';
import { getAttendanceData } from '../../store/kitchens/actions';
import agGridLocales from '../../translations/ag-grid';
import { setLayoutOptions } from '../../store/app/actions';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import search from '../../assets/icons/search.png';
// import { useLocation } from 'react-router-dom';
import { getPaymentPreferences } from '../../store/kitchens/actions';
import Pagination from 'react-bootstrap/Pagination';
import cancel from '../../assets/images/close.svg';
import http from '../../services/http';
import { Alert } from '../../components/Dialogs/SwalDialogs';

// interface CustomizedState {
//   mealDate:string,
//   mealTypeId:string
// }

const AttendancePage = ({
  kitchen,
  attendanceData,
  getAttendanceData,
  useFetchingLoader,
  totalAttendanceRecords,
  fieldNamesSettings,
}) => {
  const { t, i18n } = useTranslation();
  // const location = useLocation<any>();
  //const state = location.state as CustomizedState;

  const [reserData, setReserData] = useState([{}]);

  const [gridApi, setGridApi] = useState<any>();
  const [, setColumnApi] = useState<any>();
  const [fromDate, setFromdate] = useState<any>(new Date());
  const [fromDateFormat, setfromDateFormat] = useState();
  const [toDateFormat, settoDateFormat] = useState();
  const [toDate, setToDate] = useState<any>(new Date());
  const [tableColumn, setTableColumn] = useState<any>();
  const [searchText, setSearchText] = useState<any>('');
  const [currentPageAllLogs, setcurrentPageAllLogs] = useState(1); // Current page
  const [newPageSizeLogs] = useState(25); // Records per page
  const [pageCountsLogs, setpageCountsLogs] = useState(1); // Total pages count
  const [columnAndOrder, setColumnAndOrder] = useState<any>({});
  const [type, setType] = useState<any>('');
  const [userId, setUserId] = useState(null);
  useEffect(() => {
    gridApi?.setRowData(attendanceData);
    setReserData(attendanceData);
  }, [attendanceData]);
  const lang = localStorage.getItem('language');

  const columnTotalUserDefs = [
    {
      field: 'firstName',
      headerName: `${t('firstNamePH')}`,
      sortable: true,
      comparator: () => {},
      cellRenderer: (params) => {
        return params?.data?.firstName ? `${params?.data?.firstName}` : '--';
      },
    },
    {
      field: 'lastName',
      headerName: `${t('lastNamePH')}`,
      sortable: true,
      comparator: () => {},
      cellRenderer: (params) => {
        return params?.data?.lastName ? `${params?.data?.lastName}` : '--';
      },
    },
    {
      field: 'cardId',
      headerName: fieldNamesSettings?.find((el) => el.value === 'cardId')
        ? fieldNamesSettings
            ?.find((el) => el.value === 'cardId')
            ?.translations.find((el) => el.language.shortCode == i18n.language)
            ?.text
        : t('cardId'),
      sortable: true,
      comparator: () => {},
      cellRenderer: (params) => {
        return params?.data?.cardId && `${params?.data?.cardId}` != 'null'
          ? `${params?.data?.cardId}`
          : '--';
      },
    },
    {
      field: 'employeeId',
      headerName: fieldNamesSettings?.find((el) => el.value === 'employeeId')
        ? fieldNamesSettings
            .find((el) => el.value === 'employeeId')
            .translations?.find((el) => el.language.shortCode == i18n.language)
            ?.text
        : t('employeeId'),
      sortable: true,
      comparator: () => {},
      cellRenderer: (params) => {
        return params?.data?.employeeId &&
          `${params?.data?.employeeId}` != 'null'
          ? `${params?.data?.employeeId}`
          : '--';
      },
    },
    {
      field: 'date',
      headerName: ` ${t('date')}`,
      sortable: true,
      comparator: () => {},
      cellRenderer: (params) => {
        return params?.data?.date && `${params?.data?.date}` != 'null'
          ? `${params?.data?.date}`
          : '--';
      },
    },
    {
      field: 'fileName',
      headerName: ` ${t('fileName')}`,
      sortable: false,
      comparator: () => {},
      cellRenderer: (params) => {
        return params?.data?.fileName ? `${params?.data?.fileName}` : '--';
      },
    },
    {
      field: 'attendenceTime',
      headerName: t('time'),
      sortable: true,
      comparator: () => {},
      cellRenderer: (params) => {
        return params?.data?.attendenceTime &&
          `${params?.data?.attendenceTime}` != 'null'
          ? `${params?.data?.attendenceTime}`
          : '--';
      },
    },
    {
      field: 'timeShift',
      headerName: ` ${t('type')}`,
      sortable: true,
      comparator: () => {},
      cellRenderer: 'TypeCellRender',
    },
  ];

  const mobcolumnTotalUserDefs = [
    {
      field: 'firstName',
      headerName: `${t('firstNamePH')}`,
      sortable: true,
      comparator: () => {},
      suppressMovable: true,
      cellRenderer: (params) => {
        return params?.data?.firstName ? `${params?.data?.firstName}` : '--';
      },
    },
    {
      field: 'lastName',
      headerName: `${t('lastNamePH')}`,
      sortable: true,
      comparator: () => {},
      cellRenderer: (params) => {
        return params?.data?.lastName ? `${params?.data?.lastName}` : '--';
      },
    },
    {
      field: 'date',
      headerName: ` ${t('date')}`,
      cellRenderer: (params) => {
        return params?.data?.date && `${params?.data?.date}` != 'null'
          ? `${params?.data?.date}`
          : '--';
      },
    },
    {
      field: 'type',
      headerName: ` ${t('type')}`,
      sortable: true,
      comparator: () => {},
      cellRenderer: 'TypeCellRender',
    },
  ];

  useEffect(() => {
    if (attendanceData) {
      setpageCountsLogs(Math.ceil(totalAttendanceRecords / newPageSizeLogs));
    }
  }, [attendanceData]);

  useEffect(() => {
    if (attendanceData) {
      setpageCountsLogs(Math.ceil(totalAttendanceRecords / newPageSizeLogs));
    }
  }, []);

  const printSortStateToConsole = () => {
    const sortState = gridApi.getSortModel();
    if (sortState.length === 0) {
      setColumnAndOrder({});
    } else {
      setColumnAndOrder({
        field: sortState[0].colId,
        order: sortState[0].sort,
      });
    }
  };
  const fetchData = async () => {
    // handleReset()
    let url = window.location.href;
    let split = url.split('?');
    let userID;
    let fromDateUrl;
    let toDateUrl;
    if (split[1]) {
      const splited = split[1].split('&');
      if (splited[0]?.split('=')[0] === 'user') {
        userID = splited[0].split('=')[1];
        setUserId(userID);
        if (splited[1] && splited[1]?.split('=')[0] === 'fromDate') {
          fromDateUrl = splited[1].split('=')[1];
          toDateUrl = splited[2].split('=')[1];
          setFromdate(new Date(fromDateUrl));
          setToDate(new Date(toDateUrl));
        }
      }
    }
    useFetchingLoader(async () => {
      await getAttendanceData(
        moment(fromDateUrl ?? fromDate).format('YYYY-MM-DD'),
        moment(toDateUrl ?? toDate).format('YYYY-MM-DD'),
        type,
        currentPageAllLogs,
        newPageSizeLogs,
        searchText ? searchText : '',
        'field' in columnAndOrder ? columnAndOrder?.field : 'firstName',
        'order' in columnAndOrder ? columnAndOrder?.order : 'desc',
        userID || ''
      );
    });
  };
  useEffect(() => {
    setcurrentPageAllLogs(1);
    fetchData();
  }, [type]);
  useEffect(() => {
    fetchData();
  }, [columnAndOrder, currentPageAllLogs]);

  const handleChange = async (name: any, value: any) => {
    if (name === 'fromdate') {
      if (!value) {
        setFromdate(null);
      }
      setfromDateFormat(value);
      setFromdate(new Date(value));
    } else if (name === 'todate') {
      if (!value) {
        setToDate(null);
      }
      const tempToDate = new Date(value);
      settoDateFormat(value);
      setToDate(tempToDate);
    }
    /*if (window.innerWidth <= 480) {
      setTableColumn(mobcolumnTotalUserDefs);
    } else {
      setTableColumn(columnTotalUserDefs);
    }*/
  };
  const handleApplyfilter = async (fromDate: any) => {
    setcurrentPageAllLogs(1);
    let url = window.location.href.split('&')[0];
    window.location.href = url;
    let split = url.split('?');
    let userID;
    if (split[1]) {
      const splited = split[1].split('&');
      if (splited[0]?.split('=')[0] === 'user') {
        userID = splited[0].split('=')[1];
        setUserId(userID);
      }
    }
    useFetchingLoader(async () => {
      await getAttendanceData(
        moment(fromDate).format('YYYY-MM-DD'),
        moment(toDate).format('YYYY-MM-DD'),
        type,
        currentPageAllLogs,
        newPageSizeLogs,
        searchText ? searchText : '',
        'field' in columnAndOrder ? columnAndOrder?.field : 'firstName',
        'order' in columnAndOrder ? columnAndOrder?.order : 'desc',
        userID || ''
      );
    });
  };

  const handleFilterChanged = (e) => {
    const value = e.target.value;
    setSearchText(value);
  };

  const gridOptions = {
    onFilterChanged: handleFilterChanged,
    rowHeight: 60,
    groupHeaderHeight: 60,
    headerHeight: 60,
    rowStyle: {
      'border-bottom': 'white 10px solid',
      'border-top': 'white 0px solid',
      'border-radius': '5px',
      padding: '0px 5px',
    },
  };

  useEffect(() => {
    if (window.innerWidth <= 480) {
      setTableColumn(mobcolumnTotalUserDefs);
    } else {
      setTableColumn(columnTotalUserDefs);
    }
  }, []);

  const handleChangeType = (event) => {
    setType(event.target.value);
  };
  const handleReset = () => {
    let split = window.location.href.split('?')[0];
    window.location.href = split;
    setColumnAndOrder({});
    setSearchText('');
    setToDate(new Date());
    setFromdate(new Date());
    settoDateFormat(undefined);
    setfromDateFormat(undefined);
    setType('');
    setUserId(null);
    setcurrentPageAllLogs(1);
  };
  const handleSync = async () => {
    try {
      await useFetchingLoader(
        async () => await http.get(`users/syncAttendance`, { sync: true })
      );
      handleApplyfilter(fromDate);
    } catch (err) {
      console.log('e');
      setTimeout(() => Alert({ error: err }), 150);
    }
  };
  return (
    <>
      <div className="reservation-management global-data-table reservation-updated-page">
        <div className="container">
          <div className="add-employee-btn">
            <div className="left-filter">
              <div className="filter-section">
                <div className="search-filter">
                  {
                    <div className="xyz">
                      <div className="from-date">
                        <label>{t('fromDate')}</label>
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          selected={fromDateFormat || fromDate}
                          name="fromDate"
                          onChange={(event) => handleChange('fromdate', event)}
                          placeholder="DD/MM/YYYY"
                        />
                      </div>
                      <div className="from-date">
                        <label>{t('toDate')}</label>
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          selected={toDateFormat || toDate}
                          name="toDate"
                          onChange={(event) => handleChange('todate', event)}
                          placeholder="DD/MM/YYYY"
                        />
                      </div>
                    </div>
                  }
                  <div className="from-date">
                    <label>{t('type')}</label>
                    <select
                      name="userType"
                      onChange={(event) => handleChangeType(event)}
                      value={type}
                    >
                      <option value={''}>{t('all')}</option>
                      <option value="A100">{t('entry')}</option>
                      <option value="B200">{t('exit')}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="search-input-wrapper">
                <div className="search-input searchBtn">
                  <label>{t('search')}</label>
                  <input
                    type="text"
                    id="filter-text-box"
                    placeholder={t('freeTextSearch')}
                    onInput={handleFilterChanged}
                    value={searchText}
                  />
                  <img src={search} alt="search" />
                </div>
                {fromDate && toDate ? (
                  <button
                    onClick={() => handleApplyfilter(fromDate)}
                    className="search-button"
                  >
                    <img src={search} alt="search" />
                  </button>
                ) : (
                  ''
                )}
                <button onClick={() => handleReset()} className="reset-button">
                  <img src={cancel} alt="reset" />
                </button>

                <button onClick={() => handleSync()} className="search-button">
                  <i className="las la-sync color-white"></i>
                </button>
              </div>
            </div>
            <div className="active-button">
              <div className="total-data">
                <p>
                  {t('totalData')}: <span>{totalAttendanceRecords}</span>
                </p>
                {userId && (
                  <p>
                    {t('userId')}: <span>{userId}</span>
                  </p>
                )}
              </div>
            </div>
          </div>
          <div
            className="ag-theme-alpine"
            style={{ height: '100%', width: '100%' }}
          >
            <AgGridReact
              className="ag-theme-alpine"
              rowData={
                attendanceData.length !== 0 && reserData.length !== 0
                  ? reserData.map((reservation) => ({
                      ...reservation,
                      kitchen,
                    }))
                  : []
              }
              onSortChanged={() => printSortStateToConsole()}
              columnDefs={
                tableColumn !== undefined && attendanceData.length !== 0
                  ? tableColumn
                  : []
              }
              frameworkComponents={{
                TypeCellRender: (e) => <TypeCellRender e={e} t={t} />,
              }}
              localeText={agGridLocales[i18n.language]}
              domLayout={'autoHeight'}
              gridOptions={gridOptions}
              onGridReady={(params) => {
                setGridApi(params.api);
                setColumnApi(params.columnApi);
                params.api.sizeColumnsToFit();
              }}
              onGridColumnsChanged={(params) => {
                setGridApi(params.api);
                setColumnApi(params.columnApi);
                params.api.sizeColumnsToFit();
              }}
              onFirstDataRendered={(params) => {
                params.api.sizeColumnsToFit();
              }}
              reactUi={true}
              enableRtl={lang === '"he"' ? true : false}
              overlayLoadingTemplate={'<p></p>'}
            ></AgGridReact>
            <div className="pagination-view we-pagination-view">
              <div className="we_show_records_main">
                <p>
                  {newPageSizeLogs * (currentPageAllLogs - 1) + 1} to{' '}
                  {newPageSizeLogs * currentPageAllLogs} of{' '}
                  {totalAttendanceRecords}
                </p>
              </div>
              <Pagination>
                <Pagination.First
                  disabled={pageCountsLogs <= 1 ? true : false}
                  onClick={() => {
                    setcurrentPageAllLogs(1);
                  }}
                />
                <Pagination.Prev
                  disabled={pageCountsLogs <= 1 ? true : false}
                  onClick={() => {
                    currentPageAllLogs > 1
                      ? setcurrentPageAllLogs(currentPageAllLogs - 1)
                      : '';
                  }}
                />

                <Pagination.Item active>
                  Page {currentPageAllLogs} of{' '}
                  {Math.ceil(totalAttendanceRecords / newPageSizeLogs)}
                </Pagination.Item>

                <Pagination.Next
                  disabled={pageCountsLogs <= 1 ? true : false}
                  onClick={() => {
                    currentPageAllLogs <
                    Math.ceil(totalAttendanceRecords / newPageSizeLogs)
                      ? setcurrentPageAllLogs(currentPageAllLogs + 1)
                      : '';
                  }}
                />
                <Pagination.Last
                  disabled={pageCountsLogs <= 1 ? true : false}
                  onClick={() => {
                    setcurrentPageAllLogs(
                      Math.ceil(totalAttendanceRecords / newPageSizeLogs)
                    );
                  }}
                />
              </Pagination>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const TypeCellRender = ({ e, t }) => {
  let style = '';
  let text = '--';
  if (e.data.timeShift === 'A100') {
    text = 'entry';
  }
  if (e.data.timeShift === 'B200') {
    text = 'exit';
  }
  return (
    <div className="name-section">
      <p className={style}>{t(text)}</p>
    </div>
  );
};
const mapStateToProps = (state) => ({
  attendanceData: state.kitchens.attendanceData,
  totalAttendanceRecords: state.kitchens.totalAttendanceRecords,
  kitchen: state.kitchens.current,
  Languages: state.app.enums.Languages,
  currentLanguage: state.languages.current,
  MealTypes: state.app.enums.MealType,
  fieldNamesSettings: state.companyConfig.fieldNamesSettings,
});

const mapDispatchToProps = {
  getReportTypeReservations,
  getAttendanceData,
  setLayoutOptions,
  useFetchingLoader,
  getPaymentPreferences,
};

// export default ReservationManagement;
export default connect(mapStateToProps, mapDispatchToProps)(AttendancePage);
