import React, { useEffect, useRef, useState, useMemo } from 'react';
import {
  setLayoutOptions,
  useFetchingLoader,
  useLoader,
  showAlert,
} from '../../store/app/actions';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import WEIcon from '../../components/WE-IconButton';
import { useTranslation } from 'react-i18next';
import http from '../../services/http';
import Select from 'react-select';
import SearchLineWithButton from '../../components/SearchLineWithButton';
import User from './User';
import { isTabletOrMobile } from '../../services/mediaQueries';
import { sleep } from '../../services/utils';
import { Alert, Dialog } from '../../components/Dialogs/SwalDialogs';
import AddUserPopup from './AddUser';
import { ActiveButton } from '../../components/WE-Button';
import {
  validateEmployeenew,
  validateGuestUsersNew,
} from '../../services/validators';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CopyUrlUser from './copyUrlUser';
import ConfirmationPopup from './confirmation';
import { Autocomplete } from '@material-ui/lab';
import {
  Modal,
  Box,
  Grid,
  styled,
  Typography,
  Paper,
  Tooltip,
} from '@material-ui/core';
import more from '../../assets/images/more.svg';
import file from '../../assets/images/file-export.svg';
import adduser from '../../assets/images/add-user.svg';
import search from '../../assets/icons/search.png';
import Tippy from '@tippyjs/react';
import next from '../../assets/images/next.png';
import ViewDetails from './viewDetails';
import moment from 'moment';
import agGridLocales from '../../translations/ag-grid';
import { getAllUser, usersTotal } from '../../store/user/actions';
import { getUserConfigByCompanyId } from '../../store/companyConfig/actions';
import Pagination from 'react-bootstrap/Pagination';
import WESpinner from '../../../src/components/WE-Spinner';
import Toast from 'react-bootstrap/Toast';
import cancel from '../../assets/images/close.svg';
import * as SwalDialogs from '../../components/Dialogs/SwalDialogs';
import countryList from 'react-select-country-list';
import { VariableSizeList as List } from 'react-window';
import Excel from 'exceljs';
const initialState = {
  userId: null,
  fName: '',
  lName: '',
  phone: '',
  email: '',
  password: '',
  role: [],
  kitchensIds: [],
  employeeId: '',
  card: '',
  status: 'active',
  contractorId: '',
  contractors: [],
  magneticStripe: '',
  mealTypeId: [],
  hireTill: '',
  hireFrom: '',
  isPasswordTemporary: false,
  isMultiOrders: false,
  company: '',
  visitorCountry: '',
  visitPurpose: '',
  guestId: '',
  invitedBy: '',
};

const UserManagement = ({
  user,
  kitchen,
  setLayoutOptions,
  Roles,
  UserStatuses,
  Kitchens,
  useLoader,
  useFetchingLoader,
  languages,
  CurrentLanguage,
  getAllUser,
  usersTotal,
  allUsers,
  allUserData,
  cibusEnabled,
  companyId,
  getUserConfigByCompanyId,
  cardGenerationUrlForUser,
  cardNumberLengthForUser,
  employeeNumberLengthForUser,
  companyName,
  isStrippedCard,
  attendanceEnabled,
  fieldNamesSettings,
}) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [usersToDisplay, setUsersToDisplay] = useState(null);
  const [kitchensIds, setKitchensIds] = useState([]);
  const [payload, setPayload] = useState(initialState);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [open, setOpen] = useState(false);
  const [gridApi, setGridApi] = useState();
  const [columnApi, setColumnApi] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [update, setUpdate] = useState();
  const [isUpdate, setIsUpdate] = useState(false);
  const [prefetchData, setPrefetchData] = useState(null);
  const [rolesIds, setRolesIds] = useState();
  const [dummy, setDummy] = useState();
  const [copyData, setCopyData] = useState();
  const [resendData, setResendData] = useState();
  const [copied, setCopied] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [contractorList, setContractorList] = useState();
  const [showContractor, setShowContractor] = useState(false);
  const [errors, setErrors] = useState({});
  const [selectContractor, setSelectContractor] = useState();
  const [viewDetails, setViewDetails] = useState(false);
  const [details, setDetails] = useState();
  const [tableColumn, setTableColumn] = useState();
  const [showGuestUser, setshowGuestUser] = useState(false);
  const [selectGuestUser, setselectGuestUser] = useState();
  const [loading, setLoading] = useState(false);
  const [mealTypesIds, setMealTypesIds] = useState([]);
  const [getMealTypes, setGetMealTypes] = useState();
  const [rowCount, setRowCount] = useState();
  const [searchText, setSearchText] = useState();
  const [getMealTypesKitchenWise, setMealTypesKitchenWise] = useState();
  const [isMultiOrders, setIsMultiOrders] = useState(false);
  const [newPageSizeUsers, setPageSizeUsers] = useState(25); // Records per page
  const [totalUserRecords, setTotalUserRecords] = useState(0); // Total records
  const [pageCountsUsers, setpageCountsUsers] = useState(1); // Total pages count
  const [currentPageUsers, setCurrentPageUsers] = useState(1); // Current page
  const [searchKeywordUsers, setSearchKeywordUsers] = useState(''); // Search text
  const [userRoleId, setUserRoleId] = useState('null');
  const [userStatusId, setUserStatusId] = useState('null');
  const [userContractorId, setUserContractorId] = useState('null');
  const [userKitchenId, setUserKitchenId] = useState('null');
  const [invitedByFilter, setInvitedByFilter] = useState('null');
  const [forceResetPassword, setForceResetPassword] = useState(false);
  const [columnAndOrder, setColumnAndOrder] = useState({});
  const [disableButton, setDisableButton] = useState(false);
  const [openDownload, setOpenDownload] = useState(false);
  const [userStatus, setUserStatus] = useState(5);
  const [userEmailFromReserv, setUserEmailFromReserv] = useState(false);
  const [allUsersForInvite, setAllUsersForInvite] = useState([]);
  const [successCreateModal, setSuccessCreateModal] = useState(false);
  const [successCreateModalIsEdit, setSuccessCreateModalIsEdit] =
    useState(false);
  const [successCreateModalPayload, setSuccessCreateModalPayload] =
    useState(initialState);
  const [copyToClipMess, setCopyToClipMess] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [factories, setFactories] = useState([]);

  const [visitPurposeValues, setVisitPurposeValues] = useState([
    { label: t('event'), value: 'event' },
    { label: t('meeting'), value: 'meet' },
    { label: t('conference'), value: 'conf' },
    { label: t('technician'), value: 'tech' },
  ]);
  const SelectStyles = {
    control: (provided, state) => ({
      ...provided,
      height: 'auto',
      minHeight: 40,
      width: 200,
      padding: '0',
      backgroundColor: '#eff0f7',
      border: '1px solid #eff0f7',
      boxShadow: 'none',
      borderRadius: '5px',
      fontSize: '12px',
      fontFamily: 'OpenSans',
      textAlign: 'left',
      color: '#6e6893',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: '#6e6893',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      width: 200,
      fontSize: '12px',
      padding: '3px 10px',
      fontFamily: 'OpenSans',
      color: '#6e6893',
      textAlign: 'left',
    }),
    option: (provided, state) => ({
      padding: '10px',
      width: '100%',
      fontSize: '12px',
      fontFamily: 'OpenSans',
      backgroundColor:
        state.isFocused && !state.isSelected
          ? '#cce9ff'
          : state.isSelected
            ? '#2589f5'
            : 'transparent',
      color: state.isSelected ? '#ffffff' : '#000000',
      cursor: state.isFocused ? 'pointer' : 'auto',
    }),
    menu: (provided, state) => ({
      ...provided,
      width: 200,
      fontSize: '12px',
      fontFamily: 'Open Sans',
      color: '#6e6893',
      textAlign: 'left',
    }),
  };
  const SelectStylesSmall = {
    control: (provided, state) => ({
      ...provided,
      height: 'auto',
      minHeight: 40,
      width: 178,
      padding: '0',
      backgroundColor: '#eff0f7',
      border: '1px solid #eff0f7',
      boxShadow: 'none',
      borderRadius: '5px',
      fontSize: '12px',
      fontFamily: 'OpenSans',
      textAlign: 'left',
      color: '#6e6893',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: '#6e6893',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      width: 178,
      fontSize: '12px',
      padding: '3px 10px',
      fontFamily: 'OpenSans',
      color: '#6e6893',
      textAlign: 'left',
    }),
    menu: (provided, state) => ({
      ...provided,
      width: 178,
      fontSize: '12px',
      fontFamily: 'OpenSans',
      color: '#6e6893',
      textAlign: 'left',
    }),
    option: (provided, state) => ({
      padding: '10px',
      width: '100%',
      fontSize: '12px',
      fontFamily: 'OpenSans',
      backgroundColor:
        state.isFocused && !state.isSelected
          ? '#cce9ff'
          : state.isSelected
            ? '#2589f5'
            : 'transparent',
      color: state.isSelected ? '#ffffff' : '#000000',
      cursor: state.isFocused ? 'pointer' : 'auto',
    }),
  };
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '1px solid #ededed',
    boxShadow: 24,
    padding: '15px',
    p: 4,
    minWidth: '350px',
  };
  const countryOptions = useMemo(() => countryList().getData(), []);
  const UserStatusesMap = UserStatuses.reduce((acc, status) => {
    return { ...acc, [status.value]: status.id };
  }, {});
  const RolesMap = Roles.reduce((acc, role) => {
    return { ...acc, [role.value]: role.id };
  }, {});
  /***********fetch User data */
  useEffect(() => {
    const fetch = async () => {
      getUserConfigByCompanyId(companyId);
    };
    fetch();
  }, []);
  useEffect(() => {
    const fetchData1 = async () => {
      const allUserData = await http.get(`users/getAllUsers`);
      setAllUsersForInvite(
        allUserData.response.data.map((elem) => ({
          value: elem.id,
          label: `${elem.firstName} ${elem.lastName}`,
        }))
      );
    };

    fetchData1();
  }, []);
  const getLimitUserData = async (email) => {
    let filter = {
      companyId: user.companyId,
      limit: newPageSizeUsers,
      page: currentPageUsers,
      roleId: userRoleId,
      statusId: userStatusId,
      contractorId: userContractorId,
      kitchenId: userKitchenId,
      orderField:
        'field' in columnAndOrder ? columnAndOrder?.field : 'createdAt',
      orderTarget: 'order' in columnAndOrder ? columnAndOrder?.order : 'desc',
      search: email
        ? email.trim().replace(/\+/g, '%2B')
        : searchKeywordUsers
          ? searchKeywordUsers.trim().replace(/\+/g, '%2B')
          : undefined,
      invitedById: invitedByFilter,
    };
    await useFetchingLoader(async () => {
      const getMealType = await http.get(`mealTypes`);
      setGetMealTypes(getMealType?.data);
      const contractorData = await http.get(`contractors/getAllContractors`);
      setContractorList(contractorData.data);
      await getAllUser(filter);
      const allUserData = await http.get(`users/getAllUsers`);
      setAllUsersForInvite(
        allUserData.response.data.map((elem) => ({
          value: elem.id,
          label: `${elem.firstName} ${elem.lastName}`,
        }))
      );
    });
  };
  useEffect(() => {
    const fetchData1 = async () => {
      const data = await http.get('factories');
      setFactories(
        data.response.data.map((el) => ({
          id: el?.id,
          factoryName: el.name,
          kitchens: el.kitchens.map((el) => el.id),
        }))
      );
    };
    fetchData1();
  }, []);
  useEffect(() => {
    const fetchData1 = async (email = undefined) => {
      await getLimitUserData(email);
    };
    if (window.location.hash.includes('userEmail')) {
      const email = window.location.hash.split('?')[1].split('=')[1];
      setUserEmailFromReserv(true);
      setSearchKeywordUsers(decodeURIComponent(email));
      fetchData1(email);
    } else {
      setUserEmailFromReserv(false);
      fetchData1();
    }
  }, [
    dummy,
    kitchen,
    newPageSizeUsers,
    currentPageUsers,
    columnAndOrder,
    userKitchenId,
    userContractorId,
    userStatusId,
    userRoleId,
    invitedByFilter,
  ]);
  useEffect(() => {
    const fetchData1 = async () => {
      setPrefetchData(allUsers);
      let serverUsers = allUsers?.map((u, i) => {
        const paymentMethodArr = [
          ...new Set(
            u?.kitchens.map((el) => el.kitchenPayment.paymentOption.name)
          ),
        ];
        const userStatus = UserStatuses.find((el) => el.id === u.userStatusId);
        const userRole = Roles.find((el) => el.id === u.roleId);
        const contractor = contractorList
          ? contractorList.find((el) => el.id === u.contractors[0]?.id)
          : {};
        const contractors = contractorList
          ? contractorList.filter((obj1) =>
              u.contractors.some((obj2) => obj2.id === obj1.id)
            )
          : [];
        return {
          userId: u.id,
          Name: [u.firstName, u.lastName].join(' '),
          firstName: u.firstName,
          lastName: u.lastName,
          employeeId: u.employeeId,
          'Card Number': u.cardId,
          Status: userStatus?.display,
          kitchensIds: Kitchens?.filter((obj) =>
            u?.kitchens.some((el) => el.id === obj.id)
          ).map((obj) => obj.id),
          'Registration Date': u.createdAt?.split('T')[0] ?? undefined,
          Kitchens: Kitchens?.filter((obj) =>
            u?.kitchens.some((el) => el.id === obj.id)
          )
            .map((obj) => obj.name)
            ?.join(','),
          Email: u.email,
          Role: userRole?.display,
          RoleId: userRole?.id,
          Contractor: contractor?.id,
          allContractorInfo: contractor,
          allContractors: contractors,
          contractors: u.contractors || null,
          phone: u.phone?.split(' ').join(''),
          contractorName: u?.contractorName,
          StatusVal: userStatus?.value,
          creditCard: u.creditCard,
          connectTeam: u.connectTeam,
          paymentMethod: paymentMethodArr,
          amount: u?.userWallet?.wallet?.amount,
          walletId: u?.userWallet?.walletId,
          totalReservation: u.totalReservations,
          updatedAt: u.updatedAt?.split('T')[0] ?? undefined,
          magneticStripe: u?.magneticStripe,
          hireFrom: u?.hireFrom,
          hireTill: u?.hireTill,
          factoryId: u?.factoryId,
          secondRole: u?.secondRole,
          mealTypeId:
            u?.guestUser?.mealTypeId && u?.guestUser?.mealTypeId !== 'undefined'
              ? JSON.parse(u?.guestUser?.mealTypeId)
              : [],
          generatedLink: u?.guestUser?.generatedLink,
          isUsed: u?.isUsed,
          isPasswordTemporary: u?.isPasswordTemporary,
          company: u?.guestUser?.company,
          visitorCountry: u?.guestUser?.visitorCountry,
          visitPurpose: u?.guestUser?.visitPurpose,
          guestId: u?.guestUser?.guestId,
          isMultiOrders: u?.isMultiOrders,
          invitedBy: u?.guestUser?.invitedBy,
        };
      });
      setUsersToDisplay(serverUsers);
      setRowCount(serverUsers?.length);
      setTotalUserRecords(usersTotal);
      setpageCountsUsers(Math.ceil(usersTotal / newPageSizeUsers));
      setLoading(true);
    };
    fetchData1();
  }, [dummy, kitchen, allUsers, allUserData]);

  const handleOpen = (value) => {
    if (!value) {
      setIsUpdate(true);
    } else {
      if (value === 'recreate') {
        setIsUpdate(false);
      } else {
        setPayload(initialState);
        setIsUpdate(false);
      }
    }
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setIsUpdate(false);
    setshowGuestUser(false);
    setMealTypesKitchenWise([]);
    setKitchensIds([]);
    setErrors({});
  };
  const handleChangeRoleFilter = async (userTypeId) => {
    setCurrentPageUsers(1);
    setUserRoleId(userTypeId);
  };
  const handleChangeKitchenFilter = async (userTypeId) => {
    setCurrentPageUsers(1);
    setUserKitchenId(userTypeId);
  };
  const handleChangeContractorFilter = async (userTypeId) => {
    setCurrentPageUsers(1);
    setUserContractorId(userTypeId);
  };
  const handleChangeUserStatusFilter = async (userTypeId) => {
    setCurrentPageUsers(1);
    setUserStatusId(userTypeId);
  };
  const handleChangeInvitedByFilter = async (userTypeId) => {
    setCurrentPageUsers(1);
    setInvitedByFilter(userTypeId);
  };
  /******* add New User */
  const handleAddNewUser = async (userData) => {
    userData.password =
      userData.password?.length > 0 ? userData.password : null;
    userData.email = userData.email?.length > 0 ? userData.email : null;
    userData.kitchensIds = userData.kitchensIds || [];

    try {
      let userToAdd;
      userData.employeeId =
        userData.employeeId !== '' ? userData.employeeId : null;
      userData.cardId = userData.cardId !== '' ? userData.cardId : null;
      userData.isMultiOrders = true;
      await useFetchingLoader(async () => {
        userToAdd = await http.post(`users/create`, {
          ...userData,
          companyId: user.companyId,
        });
      });
      if (userToAdd?.errorMessage === 'passwordPolicy') {
        setTimeout(() => {
          Dialog({
            text:
              t('character') +
              ' ' +
              userToAdd?.errorLetter +
              ' ' +
              t('isNotVaild') +
              ' ' +
              t('passwordSecurity'),
            type: 'error',
            buttons: {
              showCancelButton: false,
              confirmButtonText: t('passwordUpdatedConfirmButtonText'),
            },
          });
        }, 150);
      } else {
        setDummy(!dummy);
        setSuccessCreateModalPayload(userToAdd);
        setSuccessCreateModal(true);
        setSuccessCreateModalIsEdit(false);
      }
      handleClose();
    } catch (err) {
      setTimeout(() => Alert({ error: err }), 150);
      return err;
    }
  };

  // ADD GUEST USER INFO
  const handleAddNewGuestUser = async (userData) => {
    userData.password =
      userData.password?.length > 0 ? userData.password : null;
    userData.email = userData.email?.length > 0 ? userData.email : null;
    userData.kitchensIds = userData.kitchensIds || [];
    try {
      //  let userToAdd = userData;
      let userToAdd;
      userData.employeeId =
        userData.employeeId !== '' ? userData.employeeId : null;
      userData.cardId = userData.cardId !== '' ? userData.cardId : null;
      await useFetchingLoader(async () => {
        userToAdd = await http.post(`users/createGuestUser`, {
          ...userData,
          companyId: user.companyId,
        });
      });
      if (userToAdd?.id) {
        setDummy(!dummy);
        setSuccessCreateModalPayload(userToAdd);
        copyURL(userToAdd?.id);
        setSuccessCreateModal(true);
        setSuccessCreateModalIsEdit(false);
      }
      handleClose();
    } catch (err) {
      setTimeout(() => Alert({ error: err }), 150);
      return err;
    }
  };
  //details?.roleId[0]

  const handleOpenTooltip = () => {
    setCopyToClipMess(true);
    setTimeout(() => setCopyToClipMess(false), 2500);
  };

  const handleUpdateGuestUser = async () => {
    //event.preventDefault();
    try {
      let details = {
        firstName: payload.fName,
        lastName: payload.lName,
        email: payload?.email ? payload?.email : null,
        phone: payload.phone,
        kitchensIds: kitchensIds,
        password: payload.password === '' ? undefined : payload.password,
        roleId: [RolesMap.guestUser],
        contractorId: payload?.contractorId ? payload?.contractorId : null,
        contractorsIds: payload?.contractorsIds
          ? payload?.contractorsIds
          : payload?.contractors
            ? payload?.contractors.map((el) => el.id)
            : [],
        magneticStripe: payload.magneticStripe,
        hireFrom: payload.hireFrom,
        hireTill: payload.hireTill,
        mealTypeId: payload.mealTypeId,
        isPasswordTemporary: false,
        userStatusId: UserStatuses.find((el) => el.display === payload.status)
          ?.id,
        company: payload?.company,
        visitorCountry: payload?.visitorCountry
          ? Array.isArray(payload?.visitorCountry)
            ? payload?.visitorCountry[0]
            : payload?.visitorCountry
          : '',
        visitPurpose: payload?.visitPurpose
          ? Array.isArray(payload?.visitPurpose)
            ? payload?.visitPurpose[0]
            : payload?.visitPurpose
          : '',
        guestId: payload?.guestId,
        isMultiOrders: payload?.isMultiOrders,
        invitedBy: payload?.invitedBy,
      };
      const { isValid, errors } = validateGuestUsersNew(details);
      setErrors({ ...errors });
      if (!isValid) {
        return;
      }

      const userId = payload.userId;
      const response = await useFetchingLoader(
        async () =>
          await http.put(`users/updateGuestUser/${userId}`, {
            ...details,
            phone: details.phone !== '' ? details.phone : details.phone,
          })
      );
      if (response) {
        setViewDetails(false);
        setDummy(!dummy);
        handleClose();
        setSuccessCreateModalPayload(response);
        copyURL(userId);
        setSuccessCreateModal(true);
        setSuccessCreateModalIsEdit(true);
        setKitchensIds([]);
      }
    } catch (err) {
      setTimeout(() => Alert({ error: err }), 150);
      return err;
    }
  };

  const handleUpdateUser = async () => {
    // userData.employeeId = userData.employeeId!==''?userData.employeeId:null;
    // userData.cardId = userData.cardId!==''?userData.cardId:null;
    try {
      let details = {
        firstName: payload.fName,
        lastName: payload.lName,
        email: payload.email,
        phone: payload.phone,
        // userStatusId:prefetchData?.find(el => el.id === payload.userId)?.userStatusId,
        userStatusId: UserStatuses.find((el) => el.display === payload.status)
          .id,
        kitchensIds: payload.kitchensIds,
        password: payload.password === '' ? undefined : payload.password,
        cardId: payload?.card
          ? isStrippedCard
            ? payload.card.replace(/^0+/, '')
            : payload.card
          : null,
        employeeId: payload.employeeId ? payload.employeeId : null,
        roleId: payload.role,
        value: payload.status,
        contractorsIds:
          payload.contractorsIds || payload.contractors
            ? payload.contractorsIds || payload.contractors.map((el) => el.id)
            : null,
        magneticStripe: payload.magneticStripe,
        isPasswordTemporary: payload.isPasswordTemporary,
        secondRole: payload?.secondRole,
      };
      const { isValid, errors } = validateEmployeenew(
        details,
        cardNumberLengthForUser,
        employeeNumberLengthForUser,
        localStorage.getItem('companyId')
      );
      setErrors({ ...errors });
      if (details?.kitchensIds?.length == 0) {
        return setErrors({ ...errors, kitchensIds: 'Kitchen is required' });
      }
      if (!details?.roleId?.[0]) {
        return setErrors({ ...errors, role: 'Role is required' });
      }
      if (
        details?.roleId &&
        (details?.roleId?.[0] == 6 || details?.roleId?.[0] == 7)
      ) {
        if (!details?.contractorsIds || details?.contractorsIds.length === 0) {
          return setErrors({
            ...errors,
            contractorId: 'Contractor is required',
          });
        }
      }
      if (!isValid) {
        return;
      }
      const userId = payload.userId;
      const response = await useFetchingLoader(
        async () =>
          await http.put(`users/${userId}`, {
            ...details,
            phone: details.phone !== '' ? details.phone : details.phone,
          })
      );
      setDummy(!dummy);
      setViewDetails(false);
      handleClose();
      setSuccessCreateModalPayload(response);
      setSuccessCreateModal(true);
      setSuccessCreateModalIsEdit(true);
      setKitchensIds([]);
    } catch (err) {
      setTimeout(() => Alert({ error: err }), 150);
      return err;
    }
  };

  const handleDeleteUser = async (userId) => {
    try {
      let details = {
        userStatusId: userStatus,
      };
      const response = await useFetchingLoader(
        async () => await http.put(`users/${deleteId}`, { ...details })
      );
      await setConfirmation(false);
      if (viewDetails) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
      await setViewDetails(!viewDetails);
      if (response) {
        await Dialog({
          title: t(userStatus == 3 ? 'activatedSuccessfully' : 'deleteMessage'),
          type: 'success',
          buttons: {
            showCancelButton: false,
            confirmButtonText: t('confirm'),
          },
          onConfirm: () => {
            setDummy((prev) => !prev);
            setshowGuestUser(false);
          },
        });
      }
      // setDummy(false);
      setDeleteId('');
    } catch (err) {
      setTimeout(() => Alert({ error: err }), 150);
      setConfirmation(false);
      return err;
    }
  };
  /************global filter funcitionm */
  // const onFilterTextBoxChanged = (e) => {
  //   const value = e.target.value;
  //   gridApi.setQuickFilter(value);
  // };
  const handleReset = () => {
    let split = window.location.href.split('?')[0];
    window.location.href = split;
    setColumnAndOrder({});
    setSearchKeywordUsers('');
    setUserRoleId('null');
    setUserStatusId('null');
    setUserContractorId('null');
    setUserKitchenId('null');
    setInvitedByFilter('null');
    gridApi.setSortModel([]);
    setCurrentPageUsers(1);
    setUserEmailFromReserv(false);
  };
  const getUserData = () => {
    getLimitUserData();
  };
  useEffect(() => {
    const handleResize = (event) => {
      setWidth(event.target.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const handleFilterChanged = (e) => {
    setCurrentPageUsers(1);
    const value = e.target.value;
    setSearchKeywordUsers(value);
    //setSearchText(value);
    //gridApi.setQuickFilter(value);
    // const rowCount = gridApi.getModel().rowsToDisplay.length;
    // setRowCount(rowCount);
  };

  const gridOptions = {
    onFilterChanged: handleFilterChanged,
    rowHeight: 60,
    groupHeaderHeight: 60,
    headerHeight: 60,
    rowStyle: {
      'border-bottom': 'white 10px solid',
      'border-top': 'white 0px solid',
      'border-radius': '5px',
      padding: '0px 5px',
    },
  };
  /***********Export CSV file */
  const onBtnExport = async (fileName) => {
    try {
      if (disableButton || usersToDisplay.length === 0) {
        console.log('Disabled or empty table');
      } else {
        setDisableButton(true);
        setOpenDownload(true);
        const arr = [
          'firstNamePH',
          'lastNamePH',
          'email',
          'user_type',
          'status',
          fieldNamesSettings?.find((el) => el.value === 'cardId')
            ? fieldNamesSettings
                ?.find((el) => el.value === 'cardId')
                ?.translations.find(
                  (el) => el.language.shortCode == i18n.language
                )?.text
            : 'cardIdPH',
          fieldNamesSettings?.find((el) => el.value === 'employeeId')
            ? fieldNamesSettings
                ?.find((el) => el.value === 'employeeId')
                ?.translations.find(
                  (el) => el.language.shortCode == i18n.language
                )?.text
            : 'employeeNo',
          'kitchens',
          'phonePH',
          'contractorName',
          'paymentMethod',
          'updatedOn',
          'startDate',
          cibusEnabled
            ? fieldNamesSettings?.find((el) => el.value === 'magneticStripe')
              ? fieldNamesSettings
                  ?.find((el) => el.value === 'magneticStripe')
                  ?.translations.find(
                    (el) => el.language.shortCode == i18n.language
                  )?.text
              : 'magneticStripe'
            : undefined,
          'guestLink',
          'registrationDate',
          'endDate',
          'factory',
        ].filter((el) => el !== undefined);
        const resArr = arr.map((el) => t(el));
        const filter = {
          companyId: localStorage.getItem('companyId'),
          limit: totalUserRecords,
          page: 1,
          keyword: searchKeywordUsers,
          roleId: userRoleId,
          statusId: userStatusId,
          contractorId: userContractorId,
          kitchenId: userKitchenId,
          orderField:
            'field' in columnAndOrder ? columnAndOrder?.field : 'firstName',
          orderTarget:
            'order' in columnAndOrder ? columnAndOrder?.order : 'desc',
          search: searchKeywordUsers
            ? searchKeywordUsers.trim().replace(/\+/g, '%2B')
            : undefined,
          invitedById: invitedByFilter,
        };
        for (const key in filter) {
          if (
            filter[key] === undefined ||
            filter[key] === null ||
            filter[key] === 'null'
          ) {
            delete filter[key];
          }
        }
        const res = await http.post(
          `users/download`,
          {
            isReport: true,
            headers: resArr,
          },
          { ...filter }
        );
        const workbook = new Excel.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');
        worksheet.addRows(res.data);
        const uint8Array = await workbook.xlsx.writeBuffer();
        const blob = new Blob([uint8Array], {
          type: 'application/octet-binary',
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${fileName}.xlsx`;
        a.click();
        a.remove();
        setDisableButton(false);
        setOpenDownload(false);
      }
    } catch (e) {
      console.log('e', e);
      setDisableButton(false);
      setOpenDownload(false);
    }
  };

  const onPageSizeChanged = (newPageSize) => {
    var value = document.getElementById('page-size').value;
    gridApi.paginationSetPageSize(Number(value));
  };

  // ag grid data

  const defaultColDef = {
    sortable: true,
    resizable: true,
    suppressMovable: true,
    // sizeColumnsToFit: true,
    suppressSizeToFit: true,
    width: 'auto',
  };

  const onFirstDataRendered = (params) => {
    // arbitrarily expand a row for presentational purposes
    setTimeout(function () {
      params.api.getDisplayedRowAtIndex(1).setExpanded(true);
    }, 0);
  };

  // const gridOptions = {
  //   rowHeight: 60,
  //   groupHeaderHeight: 60,
  //   headerHeight: 60,
  //   rowStyle: {
  //     'border-bottom': 'white 10px solid',
  //     'border-top': 'white 0px solid',
  //     'border-radius': '5px',
  //     padding: '0px 5px',
  //   },
  // };

  const ResendVerification = async (el) => {
    const res = await useFetchingLoader(
      async () =>
        await http.post(`users/resetPassword`, {
          userData: { email: el.Email, resendLink: true },
          companyId: localStorage.getItem('companyId'),
        })
    );
    if (res?.sent) {
      setTimeout(() => {
        Dialog({
          title: t('resendMessage'),
          type: 'success',
          buttons: {
            showCancelButton: false,
            confirmButtonText: t('confirm'),
          },
        });
      }, 150);
    }
  };
  const copyURL = async (userId) => {
    const res = await useFetchingLoader(async () =>
      http.post(`users/guestLinkCopy`, {
        userData: { userId: userId },
      })
    );
    await setResendData(res?.guestUrl);
  };
  const CopyDataUrl = async (el) => {
    if (
      (Array.isArray(el?.RoleId) && el?.RoleId[0] === 8) || // temporary fix, need to change this
      el?.RoleId === 8
    ) {
      const res = await useFetchingLoader(
        async () =>
          await http.post(`users/guestLinkCopy`, {
            userData: { userId: el?.userId },
          })
      );
      res.resetPasswordUrl = res?.guestUrl;
      setResendData(res);
    } else {
      const res = await useFetchingLoader(
        async () =>
          await http.post(`users/copyUrl`, {
            userData: { email: el.Email },
          })
      );
      setResendData(res);
    }
    setCopyData(true);
  };
  const handleCloseUrl = () => {
    setCopyData(false);
    setCopied(false);
  };
  const handleConfirmation = (userId, status = 5) => {
    setUserStatus(status);
    setConfirmation(!confirmation);

    setDeleteId(userId);
  };
  const lang = localStorage.getItem('language');
  const [newRoles, setNewRoles] = useState();
  useEffect(() => {
    if (user.roleId === 1) {
      setNewRoles(Roles);
    } else if (user.roleId !== 1) {
      let aa = Roles.filter((el) => el.id !== 1);
      setNewRoles(aa);
    }
  }, [user]);

  const handleViewMore = (data) => {
    if (viewDetails) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    setViewDetails(!viewDetails);
    setDetails(data);
  };
  const handleWallet = (walletId) => {
    history.push(`/credit?${walletId}`);
  };
  const handleOrderLink = (userId, createdAt) => {
    history.push(
      `/reservationManagement?user=${userId}&createdAt=${createdAt}`
    );
  };
  useEffect(() => {
    const columnDefs = [
      {
        field: 'firstName',
        headerName: t('userName'),
        sortable: true,
        suppressMovable: true,
        headerClass: 'resizable-header',
        cellRenderer: 'NameCellRender',
        comparator: () => {},
        resizable: true,
      },
      {
        field: 'email',
        headerName: t('email'),
        sortable: true,
        suppressMovable: true,
        headerClass: 'resizable-header',
        comparator: () => {},
        resizable: true,
        hide: true,
      },
      {
        field: 'employeeId',
        headerName: fieldNamesSettings?.find((el) => el.value === 'employeeId')
          ? fieldNamesSettings
              ?.find((el) => el.value === 'employeeId')
              ?.translations.find(
                (el) => el.language.shortCode == i18n.language
              )?.text
          : t('employeeNo'),
        comparator: () => {},
        sortable: true,
        suppressMovable: true,
        resizable: true,
        cellRenderer: 'EmployeeIdCellRender',
      },
      {
        field: 'roleId',
        headerName: t('userType'),
        suppressMovable: true,
        cellRenderer: 'StatusCellRender',
        resizable: true,
      },
      {
        field: 'cardId',
        headerName: fieldNamesSettings?.find((el) => el.value === 'cardId')
          ? fieldNamesSettings
              ?.find((el) => el.value === 'cardId')
              ?.translations.find(
                (el) => el.language.shortCode == i18n.language
              )?.text
          : t('cardId'),
        comparator: () => {},
        sortable: true,
        suppressMovable: true,
        resizable: true,
        cellRenderer: 'CardIdCellRender',
      },
      {
        field: 'kitchens',
        headerName: t('kitchenname'),
        comparator: () => {},
        suppressMovable: true,
        resizable: true,
        cellRenderer: 'KitcheCellRender',
      },
      {
        field: 'phone',
        headerName: t('phone'),
        comparator: () => {},
        sortable: true,
        suppressMovable: true,
        resizable: true,
        cellRenderer: 'PhoneCellRender',
      },
      {
        field: 'contractorName',
        headerName: t('contractorName'),
        comparator: () => {},
        suppressMovable: true,
        resizable: true,
        cellRenderer: 'ContractorCellRender',
      },

      {
        field: 'Payment Method',
        headerName: t('paymentMethod'),
        comparator: () => {},
        cellRenderer: 'PaymentMethod',
        suppressMovable: true,
      },
      {
        field: 'totalReservations',
        headerName: t('reservations'),
        cellRenderer: 'OrderLink',
        suppressMovable: true,
      },
      {
        field: 'magneticStripe',
        headerName: fieldNamesSettings?.find(
          (el) => el.value === 'magneticStripe'
        )
          ? fieldNamesSettings
              ?.find((el) => el.value === 'magneticStripe')
              ?.translations.find(
                (el) => el.language.shortCode == i18n.language
              )?.text
          : t('magneticStripe'),
        comparator: () => {},
        sortable: true,
        suppressMovable: true,
        hide: true,
      },
      {
        field: '',
        headerName: '',
        cellRenderer: 'ViewMore',
        headerClass: 'resizable-header',
        suppressMovable: true,
        width: 100,
      },
    ];
    // Tab view
    const columnDefsTab = [
      {
        field: 'firstName',
        headerName: t('userName'),
        comparator: () => {},
        sortable: true,
        suppressMovable: true,
        headerClass: 'resizable-header',
        cellRenderer: 'NameCellRender',
        resizable: true,
      },
      {
        field: 'employeeId',
        headerName: fieldNamesSettings?.find((el) => el.value === 'employeeId')
          ? fieldNamesSettings
              ?.find((el) => el.value === 'employeeId')
              ?.translations.find(
                (el) => el.language.shortCode == i18n.language
              )?.text
          : t('employeeNo'),
        comparator: () => {},
        sortable: true,
        suppressMovable: true,
        resizable: true,
        cellRenderer: 'EmployeeIdCellRender',
      },
      {
        field: 'userStatusId',
        headerName: t('userType'),
        comparator: () => {},
        sortable: true,
        suppressMovable: true,
        cellRenderer: 'StatusCellRender',
        resizable: true,
      },
      {
        field: 'cardId',
        headerName: fieldNamesSettings?.find((el) => el.value === 'cardId')
          ? fieldNamesSettings
              ?.find((el) => el.value === 'cardId')
              ?.translations.find(
                (el) => el.language.shortCode == i18n.language
              )?.text
          : t('cardId'),
        comparator: () => {},
        sortable: true,
        suppressMovable: true,
        resizable: true,
        cellRenderer: 'CardIdCellRender',
      },

      {
        field: 'kitchens',
        headerName: t('kitchenname'),
        comparator: () => {},
        suppressMovable: true,
        resizable: true,
        cellRenderer: 'KitcheCellRender',
      },
      {
        field: 'phone',
        headerName: t('phone'),
        comparator: () => {},
        sortable: true,
        suppressMovable: true,
        resizable: true,
        cellRenderer: 'PhoneCellRender',
      },
      {
        field: 'magneticStripe',
        headerName: fieldNamesSettings?.find(
          (el) => el.value === 'magneticStripe'
        )
          ? fieldNamesSettings
              ?.find((el) => el.value === 'magneticStripe')
              ?.translations.find(
                (el) => el.language.shortCode == i18n.language
              )?.text
          : t('magneticStripe'),
        comparator: () => {},
        sortable: true,
        suppressMovable: true,
        hide: true,
      },
      {
        field: '',
        headerName: '',
        cellRenderer: 'ViewMore',
        headerClass: 'resizable-header',
        suppressMovable: true,
        width: 100,
      },
    ];

    /**** mobile view column */
    const columnDefsMob = [
      {
        field: 'firstName',
        headerName: t('userName'),
        comparator: () => {},
        sortable: true,
        suppressMovable: true,
        headerClass: 'resizable-header',
        cellRenderer: 'NameCellRender',
        resizable: true,
      },
      {
        field: 'email',
        headerName: t('email'),
        comparator: () => {},
        sortable: true,
        suppressMovable: true,
        headerClass: 'resizable-header',
        // cellRenderer: 'NameCellRender',
        resizable: true,
        hide: true,
      },
      {
        field: 'employeeId',
        headerName: fieldNamesSettings?.find((el) => el.value === 'employeeId')
          ? fieldNamesSettings
              .find((el) => el.value === 'employeeId')
              .translations?.find(
                (el) => el.language.shortCode == i18n.language
              )?.text
          : t('employeeNo'),
        comparator: () => {},
        sortable: true,
        suppressMovable: true,
        resizable: true,
        cellRenderer: 'EmployeeIdCellRender',
        hide: true,
      },
      {
        field: 'userStatusId',
        headerName: t('userType'),
        comparator: () => {},
        sortable: true,
        suppressMovable: true,
        cellRenderer: 'StatusCellRender',
        resizable: true,
        hide: true,
      },
      {
        field: 'cardId',
        headerName: fieldNamesSettings?.find((el) => el.value === 'cardId')
          ? fieldNamesSettings
              ?.find((el) => el.value === 'cardId')
              ?.translations.find(
                (el) => el.language.shortCode == i18n.language
              )?.text
          : t('cardId'),
        comparator: () => {},
        sortable: true,
        suppressMovable: true,
        resizable: true,
        cellRenderer: 'CardIdCellRender',
      },
      {
        field: 'kitchens',
        headerName: t('kitchenname'),
        comparator: () => {},
        suppressMovable: true,
        resizable: true,
        cellRenderer: 'KitcheCellRender',
        hide: true,
      },
      {
        field: 'phone',
        headerName: t('phone'),
        comparator: () => {},
        sortable: true,
        suppressMovable: true,
        resizable: true,
        cellRenderer: 'PhoneCellRender',
        hide: true,
      },
      {
        field: 'contractorName',
        headerName: t('contractorName'),
        comparator: () => {},
        suppressMovable: true,
        resizable: true,
        cellRenderer: 'ContractorCellRender',
        hide: true,
      },

      {
        field: 'Payment Method',
        headerName: t('paymentMethod'),
        cellRenderer: 'PaymentMethod',
        comparator: () => {},
        suppressMovable: true,
        hide: true,
      },
      {
        field: 'totalReservations',
        headerName: t('reservations'),
        cellRenderer: 'OrderLink',
        suppressMovable: true,
        hide: true,
      },
      {
        field: 'magneticStripe',
        headerName: fieldNamesSettings?.find(
          (el) => el.value === 'magneticStripe'
        )
          ? fieldNamesSettings
              ?.find((el) => el.value === 'magneticStripe')
              ?.translations.find(
                (el) => el.language.shortCode == i18n.language
              )?.text
          : t('magneticStripe'),
        comparator: () => {},
        sortable: true,
        suppressMovable: true,
        hide: true,
      },
      {
        field: '',
        headerName: '',
        cellRenderer: 'ViewMore',
        headerClass: 'resizable-header',
        suppressMovable: true,
        width: 100,
      },
    ];
    if (window.innerWidth <= 1024 && window.innerWidth >= 479) {
      setTableColumn(columnDefsTab);
    } else if (window.innerWidth <= 480) {
      setTableColumn(columnDefsMob);
    } else {
      setTableColumn(columnDefs);
    }
  }, []);

  const printSortStateToConsole = () => {
    const sortState = gridApi.getSortModel();
    if (sortState.length === 0) {
      setColumnAndOrder({});
    } else {
      setColumnAndOrder({
        field: sortState[0].colId,
        order: sortState[0].sort,
      });
    }
  };
  const handleCloseSuccessCreateModal = () => {
    setSuccessCreateModal(false);
    setSuccessCreateModalIsEdit(false);
    setSuccessCreateModalPayload({});
    setPayload(initialState);
  };
  function capitalizeEachWord(string) {
    if (string) {
      const words = string.toLowerCase().split(' ');
      const capitalizedWords = words.map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      });
      return capitalizedWords.join(' ');
    } else {
      return '';
    }
  }
  function useResetCache(data) {
    const ref = useRef(null);
    useEffect(() => {
      if (ref.current != null) {
        ref.current.resetAfterIndex(0, true);
      }
    }, [data]);
    return ref;
  }
  const height = 35;
  const MenuList = ({
    options,
    children,
    maxHeight,
    width,
    getValue,
    selectProps,
  }) => {
    const [value] = getValue();
    const canvas = document.getElementById('canvas');
    const object = document.getElementById('react-select-dropdown');
    const elementWidth = object.offsetWidth - 26;
    const ctx = canvas.getContext('2d');
    ctx.font = '12px OpenSans';
    const getItemSize = (index) => {
      let colRows = children[index]?.props
        ? Math.ceil(
            Math.round(
              ctx.measureText(children[index].props.data.label.trim()).width
            ) / elementWidth
          )
        : 1;
      colRows = colRows > 1 ? colRows * 0.75 : colRows;
      return 35 * colRows;
    };
    const gridRef = useResetCache(children.length);
    return (
      <List
        height={
          children.length
            ? children.length < 5
              ? children.reduce((acc, curr) => {
                  let colRows = curr?.props
                    ? Math.ceil(
                        Math.round(
                          ctx.measureText(curr.props.data.label.trim()).width
                        ) / 150
                      )
                    : 1;
                  colRows = colRows > 1 ? colRows * 0.75 : colRows;
                  return acc + 38 * colRows;
                }, 0)
              : 180
            : 0
        }
        ref={gridRef}
        itemCount={children.length}
        itemSize={getItemSize}
        className="customList"
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    );
  };
  return (
    <div className="user-management-table global-data-table">
      <div className="container">
        <div className="add-employee-btn">
          <div className="search-user-wrapper">
            <div className="search-block">
              <div className="search-input">
                <canvas id="canvas" width="0" height="0"></canvas>
                <img src={search} alt="search" />
                <input
                  type="text"
                  id="filter-text-box"
                  placeholder={`${t('freeTextSearch')}`}
                  onInput={handleFilterChanged}
                  value={searchKeywordUsers}
                  disabled={userEmailFromReserv}
                />
              </div>
              <button
                onClick={getUserData}
                className="search-button resize"
                disabled={userEmailFromReserv}
              >
                <img src={search} alt="search" />
              </button>
              <button
                onClick={() => handleReset()}
                className="reset-button-user-management"
              >
                <img src={cancel} alt="reset" />
              </button>
            </div>

            {/*<div className="filter-block">
              <div className="from-date w-25">
                <select
                  name="userType"
                  placeholder={t('filter')}
                  value={userRoleId}
                  onChange={(event) =>
                    handleChangeRoleFilter(event.target.value)
                  }
                  disabled={userEmailFromReserv}
                >
                  <option value="null">{t('selectUserType')}</option>
                  {user.roleId === 1 && <option value="1">{t('admin')}</option>}
                  <option value="2">{t('siteManager')}</option>
                  <option value="3">{t('kitchenManager')}</option>
                  <option value="4">{t('employee')}</option>
                  <option value="6">{t('contractorEmployee')}</option>
                  <option value="7">{t('contractorAdmin')}</option>
                  <option value="8">{t('guestUsers')}</option>
                  <option value="9">{t('kitchenWorker')}</option>
                  <option value="10">{t('viewer')}</option>
                </select>
              </div>
              <div className="from-date w-25">
                <select
                  name="userType"
                  value={userStatusId}
                  placeholder={t('filter')}
                  onChange={(event) =>
                    handleChangeUserStatusFilter(event.target.value)
                  }
                  disabled={userEmailFromReserv}
                >
                  <option value="null">{t('selectUserStatus')}</option>
                  <option value="1">{t('created')}</option>
                  <option value="2">{t('pending')}</option>
                  <option value="3">{t('active')}</option>
                  <option value="4">{t('blocked')}</option>
                  <option value="5">{t('deleted')}</option>
                </select>
              </div>
              <div className="from-date w-25">
                <select
                  name="userType"
                  value={userContractorId}
                  placeholder={t('filter')}
                  onChange={(event) =>
                    handleChangeContractorFilter(event.target.value)
                  }
                  disabled={userEmailFromReserv}
                >
                  <option value="null">{t('selectContractor')}</option>
                  {contractorList
                    ? contractorList.map((el) => (
                        <option value={el.id}>{el.name}</option>
                      ))
                    : []}
                </select>
              </div>
              <div className="from-date w-25">
                <select
                  name="userType"
                  value={userKitchenId}
                  placeholder={t('filter')}
                  onChange={(event) =>
                    handleChangeKitchenFilter(event.target.value)
                  }
                  disabled={userEmailFromReserv}
                >
                  <option value="null">{t('selectKitchen')}</option>
                  {Kitchens && user.kitchens
                    ? (user.roleId == 1
                        ? Kitchens
                        : Kitchens?.filter((el) =>
                            user?.KitchenId?.includes(el.id)
                          )
                      ).map((el) => <option value={el.id}>{el.name}</option>)
                    : []}
                </select>
              </div>
            </div>*/}
          </div>
          <div className="active-button">
            <div className="total-data">
              <p>
                {t('totalData')}: <span>{totalUserRecords}</span>
              </p>
            </div>
            {loading == 0 ? (
              <div className="loading_excel" title="Loading">
                <WESpinner size={15} color={'#14cd31'} />
              </div>
            ) : (
              ''
            )}
            <div className={`actions ${loading == 1 ? '' : 'hidden'}`}>
              {
                <img
                  src={file}
                  alt="add"
                  onClick={() =>
                    onBtnExport(
                      `${companyName}-users-${moment().format('DD-MMMM-YYYY')}`
                    )
                  }
                />
              }
              {user.roleId !== 10 && (
                <img
                  src={adduser}
                  alt="add"
                  onClick={() => handleOpen('add')}
                />
              )}
            </div>
          </div>
        </div>
        <div className="user-management-filter-container">
          <Select
            name="userType"
            placeholder={t('selectUserType')}
            value={Roles.filter((el) => el.id == userRoleId).map((el) => ({
              value: el.id,
              label: el.display,
            }))}
            components={{ MenuList }}
            isClearable={true}
            onChange={(event) =>
              handleChangeRoleFilter(event?.value ? event?.value : 'null')
            }
            disabled={userEmailFromReserv}
            options={Roles.filter((el) => el.id !== 5)
              .filter((el) => el.id !== (user.roleId === 2 ? 1 : ''))
              .map((roles) => ({
                value: roles.id,
                label: roles.display,
              }))}
            id="react-select-dropdown"
            styles={
              width > 480 && width < 525 ? SelectStylesSmall : SelectStyles
            }
          />
          <Select
            name="userStatus"
            placeholder={t('selectUserStatus')}
            value={UserStatuses.filter((el) => el.id == userStatusId).map(
              (el) => ({
                value: el.id,
                label: el.display,
              })
            )}
            components={{ MenuList }}
            isClearable={true}
            onChange={(event) =>
              handleChangeUserStatusFilter(event?.value ? event?.value : 'null')
            }
            disabled={userEmailFromReserv}
            options={UserStatuses.map((el) => ({
              value: el.id,
              label: el.display,
            }))}
            styles={
              width > 480 && width < 525 ? SelectStylesSmall : SelectStyles
            }
          />
          <Select
            name="userContractor"
            placeholder={t('selectContractor')}
            value={
              contractorList
                ? contractorList
                    .filter((el) => el.id == userContractorId)
                    .map((el) => ({
                      value: el.id,
                      label: el.name,
                    }))
                : null
            }
            components={{ MenuList }}
            isClearable={true}
            onChange={(event) =>
              handleChangeContractorFilter(event?.value ? event?.value : 'null')
            }
            disabled={userEmailFromReserv}
            options={
              contractorList
                ? contractorList.map((el) => ({
                    value: el.id,
                    label: el.name,
                  }))
                : []
            }
            styles={
              width > 480 && width < 525 ? SelectStylesSmall : SelectStyles
            }
          />
          <Select
            name="userKitchen"
            placeholder={t('selectKitchen')}
            value={
              Kitchens
                ? Kitchens.filter((el) => el.id == userKitchenId).map((el) => ({
                    value: el.id,
                    label: el.name,
                  }))
                : null
            }
            components={{ MenuList }}
            isClearable={true}
            onChange={(event) =>
              handleChangeKitchenFilter(event?.value ? event?.value : 'null')
            }
            disabled={userEmailFromReserv}
            width={'150px'}
            options={
              Kitchens && user.kitchens
                ? (user.roleId == 1
                    ? Kitchens
                    : Kitchens?.filter((el) => user?.KitchenId?.includes(el.id))
                  ).map((el) => ({
                    value: el.id,
                    label: el.name,
                  }))
                : []
            }
            styles={
              width > 480 && width < 525 ? SelectStylesSmall : SelectStyles
            }
          />
          <Select
            name="invitedBy"
            placeholder={t('invitedBy')}
            value={
              allUsersForInvite
                ? invitedByFilter === 'null'
                  ? null
                  : allUsersForInvite.find((el) => el.value == invitedByFilter)
                : null
            }
            components={{ MenuList }}
            isClearable={true}
            onChange={(event) =>
              handleChangeInvitedByFilter(event?.value ? event?.value : 'null')
            }
            disabled={userEmailFromReserv}
            options={
              allUsersForInvite
                ? allUsersForInvite.map((el) => ({
                    value: el.value,
                    label: capitalizeEachWord(el.label),
                  }))
                : []
            }
            styles={
              width > 480 && width < 525 ? SelectStylesSmall : SelectStyles
            }
          />
        </div>
        <div
          className="ag-theme-alpine"
          style={{ height: '100%', width: '100%' }}
        >
          <AgGridReact
            className="ag-theme-alpine"
            rowData={usersToDisplay}
            columnDefs={tableColumn}
            localeText={agGridLocales[i18n.language]}
            reactUi={lang === '"he"' ? false : true}
            enableRtl={lang === '"he"' ? true : false}
            domLayout={'autoHeight'}
            gridOptions={gridOptions}
            onSortChanged={() => printSortStateToConsole()}
            onGridReady={(params) => {
              setGridApi(params.api);
              setColumnApi(params.columnApi);
              params.api.sizeColumnsToFit();
              document.querySelector('.ag-pinned-left-cols-container').remove();
            }}
            onFirstDataRendered={(params) => {
              params.api.sizeColumnsToFit();
            }}
            frameworkComponents={{
              PopupCellRenderer: (e) => (
                <PopupCellRenderer
                  handleOpen={handleOpen}
                  e={e}
                  setUpdate={setUpdate}
                  setPayload={setPayload}
                  setIsEdit={setIsEdit}
                  handleDeleteUser={handleDeleteUser}
                  t={t}
                  kitchensIds={kitchensIds}
                  setKitchensIds={setKitchensIds}
                  Kitchens={Kitchens}
                  Roles={Roles}
                  ResendVerification={ResendVerification}
                  UserStatuses={UserStatuses}
                  CopyDataUrl={CopyDataUrl}
                  handleConfirmation={handleConfirmation}
                  setShowContractor={setShowContractor}
                  contractorList={contractorList}
                  handleViewMore={handleViewMore}
                />
              ),
              ViewMore: (e) => (
                <ViewMore e={e} handleViewMore={handleViewMore} />
              ),
              NameCellRender: (e) => <NameCellRender e={e} />,
              CardIdCellRender: (e) => <CardIdCellRender e={e} />,
              EmployeeIdCellRender: (e) => <EmployeeIdCellRender e={e} />,
              PhoneCellRender: (e) => <PhoneCellRender e={e} />,
              ContractorCellRender: (e) => <ContractorCellRender e={e} />,
              KitcheCellRender: (e) => <KitcheCellRender e={e} />,
              StatusCellRender: (e) => (
                <StatusCellRender e={e} t={t} Roles={Roles} />
              ),
              CreditCard: (e) => <CreditCard e={e} />,
              PaymentMethod: (e) => (
                <PaymentMethod e={e} handleWallet={handleWallet} t={t} />
              ),
              OrderLink: (e) => (
                <OrderLink e={e} handleOrderLink={handleOrderLink} />
              ),
            }}
            debug={false}
            rowSelection={'multiple'}
            rowGroupPanelShow={'always'}
            pivotPanelShow={'always'}
            enableRangeSelection={true}
            pagination={false}
            paginationPageSize={50}
            overlayLoadingTemplate={'<p></p>'}
            paginationNumberFormatter={function (params) {
              return params.value.toLocaleString();
            }}
          ></AgGridReact>
          <div className="pagination-view we-pagination-view">
            <div className="we_show_records_main">
              <p>
                {newPageSizeUsers * (currentPageUsers - 1) + 1} to{' '}
                {newPageSizeUsers * currentPageUsers} of {totalUserRecords}
              </p>
            </div>
            <Pagination>
              <Pagination.First
                disabled={pageCountsUsers <= 1 ? true : false}
                onClick={() => {
                  setCurrentPageUsers(1);
                }}
              />
              <Pagination.Prev
                disabled={pageCountsUsers <= 1 ? true : false}
                onClick={() => {
                  currentPageUsers - 1 > 0
                    ? setCurrentPageUsers(currentPageUsers - 1)
                    : '';
                }}
              />

              <Pagination.Item active>
                Page {currentPageUsers} of{' '}
                {Math.ceil(usersTotal / newPageSizeUsers)}
              </Pagination.Item>

              <Pagination.Next
                disabled={pageCountsUsers <= 1 ? true : false}
                onClick={() => {
                  currentPageUsers < Math.ceil(usersTotal / newPageSizeUsers)
                    ? setCurrentPageUsers(currentPageUsers + 1)
                    : '';
                }}
              />
              <Pagination.Last
                disabled={pageCountsUsers <= 1 ? true : false}
                onClick={() => {
                  setCurrentPageUsers(Math.ceil(usersTotal / newPageSizeUsers));
                }}
              />
            </Pagination>
          </div>
        </div>
        <AddUserPopup
          fieldNamesSettings={fieldNamesSettings}
          open={open}
          handleClose={handleClose}
          Kitchens={Kitchens}
          Roles={Roles}
          UserStatuses={UserStatuses}
          setViewDetails={setViewDetails}
          // userData={{ name: '' }}
          userData={prefetchData}
          newUserFlag={true}
          handleAddNewUser={handleAddNewUser}
          handleAddNewGuestUser={handleAddNewGuestUser}
          isEdit={true}
          payload={payload}
          setPayload={setPayload}
          countryOptions={countryOptions}
          visitPurposeValues={visitPurposeValues}
          handleUpdateUser={handleUpdateUser}
          handleUpdateGuestUser={handleUpdateGuestUser}
          setIsEdit={setIsEdit}
          isUpdate={isUpdate}
          rolesIds={rolesIds}
          setRolesIds={setRolesIds}
          capitalizeEachWord={capitalizeEachWord}
          t={t}
          i18n={i18n}
          kitchensIds={kitchensIds}
          setKitchensIds={setKitchensIds}
          cibusEnabled={cibusEnabled}
          setErrors={setErrors}
          errors={errors}
          user={user}
          newRoles={newRoles}
          contractorList={contractorList}
          showContractor={showContractor}
          setShowContractor={setShowContractor}
          setSelectContractor={setSelectContractor}
          selectContractor={selectContractor}
          setshowGuestUser={setshowGuestUser}
          showGuestUser={showGuestUser}
          setselectGuestUser={setselectGuestUser}
          selectGuestUser={selectGuestUser}
          setMealTypesIds={setMealTypesIds}
          mealTypesIds={mealTypesIds}
          setGetMealTypes={setGetMealTypes}
          getMealTypes={getMealTypes}
          forceResetPassword={forceResetPassword}
          setForceResetPassword={setForceResetPassword}
          isMultiOrders={isMultiOrders}
          setIsMultiOrders={setIsMultiOrders}
          setMealTypesKitchenWise={setMealTypesKitchenWise}
          getMealTypesKitchenWise={getMealTypesKitchenWise}
          cardGenerationUrlForUser={cardGenerationUrlForUser}
          cardNumberLengthForUser={cardNumberLengthForUser}
          employeeNumberLengthForUser={employeeNumberLengthForUser}
          setSuccessCreateModalPayload={setSuccessCreateModalPayload}
          setSuccessCreateModalIsEdit={setSuccessCreateModalIsEdit}
          setSuccessCreateModal={setSuccessCreateModal}
          allUsers={allUsersForInvite}
        />
        <CopyUrlUser
          open={copyData}
          handleClose={CopyDataUrl}
          resendData={resendData}
          handleCloseUrl={handleCloseUrl}
          copied={copied}
          setCopied={setCopied}
        />
        <ConfirmationPopup
          open={confirmation}
          userStatus={userStatus}
          handleClose={() => setConfirmation(false)}
          handleDeleteUser={handleDeleteUser}
        />
        <ViewDetails
          fieldNamesSettings={fieldNamesSettings}
          open={viewDetails}
          details={details}
          allUsers={allUsersForInvite}
          handleClose={handleViewMore}
          CopyDataUrl={CopyDataUrl}
          ResendVerification={ResendVerification}
          handleConfirmation={handleConfirmation}
          handleOpen={handleOpen}
          countryOptions={countryOptions}
          visitPurposeValues={visitPurposeValues}
          setPayload={setPayload}
          UserStatuses={UserStatuses}
          setKitchensIds={setKitchensIds}
          Kitchens={Kitchens}
          Roles={Roles}
          contractorList={contractorList}
          handleWallet={handleWallet}
          handleOrderLink={handleOrderLink}
          setShowContractor={setShowContractor}
          setMealTypesIds={setMealTypesIds}
          getMealTypes={getMealTypes}
          setMealTypesKitchenWise={setMealTypesKitchenWise}
          getMealTypesKitchenWise={getMealTypesKitchenWise}
          cibusEnabled={cibusEnabled}
          attendanceEnabled={attendanceEnabled}
          setSuccessCreateModalPayload={setSuccessCreateModalPayload}
          setSuccessCreateModal={setSuccessCreateModal}
          user={user}
          factories={factories}
        />
        <Toast
          onClose={() => setOpenDownload(false)}
          show={openDownload}
          style={{
            position: 'sticky',
            right: '20px',
            bottom: '70px',
          }}
        >
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
              onClick={() => setOpenDownload(false)}
            />
          </Toast.Header>
          <Toast.Body>{t('downloadFile')}</Toast.Body>
        </Toast>

        <Modal
          open={successCreateModal}
          onClose={() => handleCloseSuccessCreateModal()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ zIndex: 999999 }}
        >
          <Box sx={style}>
            <div className="close-btn">
              <img
                src={cancel}
                alt="close"
                onClick={() => handleCloseSuccessCreateModal()}
              />
            </div>
            <Typography id="modal-modal-description">
              <div className="user-create-success-wrapper">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="107"
                  height="104"
                  viewBox="0 0 107 104"
                  fill="none"
                >
                  <path
                    d="M27 88.5L13.25 85.5001C12 85.2501 11 84.6034 10.25 83.5601C9.5 82.5201 9.20833 81.3751 9.375 80.1251L10.75 66.0001L1.375 55.2501C0.541667 54.3334 0.125 53.2501 0.125 52.0001C0.125 50.7501 0.541667 49.6667 1.375 48.7501L10.75 38.0001L9.375 23.8751C9.20833 22.6251 9.5 21.4801 10.25 20.4401C11 19.3967 12 18.7501 13.25 18.5001L27 15.5001L34.25 3.25005C34.9167 2.16672 35.8333 1.45839 37 1.12505C38.1667 0.791721 39.3333 0.833388 40.5 1.25005L53.5 6.75005L66.5 1.25005C67.6667 0.833388 68.8333 0.791721 70 1.12505C71.1667 1.45839 72.0833 2.16672 72.75 3.25005L80 15.5001L93.75 18.5001C95 18.7501 96 19.3967 96.75 20.4401C97.5 21.4801 97.7917 22.6251 97.625 23.8751L96.25 38.0001L105.625 48.7501C106.458 49.6667 106.875 50.7501 106.875 52.0001C106.875 53.2501 106.458 54.3334 105.625 55.2501L96.25 66.0001L97.625 80.1251C97.7917 81.3751 97.5 82.5201 96.75 83.5601C96 84.6034 95 85.2501 93.75 85.5001L80 88.5L72.75 100.75C72.0833 101.833 71.1667 102.542 70 102.875C68.8333 103.208 67.6667 103.167 66.5 102.75L53.5 97.25L40.5 102.75C39.3333 103.167 38.1667 103.208 37 102.875C35.8333 102.542 34.9167 101.833 34.25 100.75L27 88.5ZM44.75 66.2501C45.6667 67.1667 46.8333 67.6251 48.25 67.6251C49.6667 67.6251 50.8333 67.1667 51.75 66.2501L73 45.0001C74 44.0001 74.5 42.8117 74.5 41.4351C74.5 40.0617 74 38.8751 73 37.8751C72 36.8751 70.8133 36.3751 69.44 36.3751C68.0633 36.3751 66.875 36.8751 65.875 37.8751L48.25 55.5001L41 48.3751C40 47.4584 38.8133 47.0201 37.44 47.0601C36.0633 47.1034 34.9167 47.5834 34 48.5001C33.0833 49.4167 32.625 50.5834 32.625 52.0001C32.625 53.4167 33.0833 54.5834 34 55.5001L44.75 66.2501Z"
                    fill="#14CD30"
                  />
                </svg>
                <div className="user-create-success-text-block">
                  <div className="user-create-success-text-block-name">
                    <p>{`${t('userCreateSuccessOne')}`} </p>

                    {successCreateModalPayload?.roleId &&
                    successCreateModalPayload?.roleId == 8 ? (
                      <div className="user-create-success-copy-block-pointer">
                        <Tooltip
                          PopperProps={{
                            disablePortal: true,
                          }}
                          open={copyToClipMess}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          title={t('copied')}
                        >
                          <CopyToClipboard
                            text={resendData}
                            onCopy={() => handleOpenTooltip()}
                          >
                            <span className={'d-flex'}>
                              <p
                                className={
                                  'user-create-success-username-highlight'
                                }
                              >{`${successCreateModalPayload.firstName} ${successCreateModalPayload.lastName}`}</p>
                            </span>
                          </CopyToClipboard>
                        </Tooltip>

                        <CopyToClipboard
                          text={resendData}
                          onCopy={() => handleOpenTooltip()}
                        >
                          <span>
                            <i class="las la-copy user-create-success-copy-icon"></i>
                          </span>
                        </CopyToClipboard>
                      </div>
                    ) : (
                      <div className={'user-create-success-copy-block'}>
                        <p
                          className={'user-create-success-username'}
                        >{`${successCreateModalPayload.firstName} ${successCreateModalPayload.lastName}`}</p>
                      </div>
                    )}
                  </div>
                  <p>
                    {successCreateModalIsEdit
                      ? t('userUpdateSuccessTwo')
                      : t('userCreateSuccessTwo')}
                  </p>
                </div>
                <div className="user-create-success-btn-wrapper">
                  <button
                    className="user-create-success-card"
                    onClick={async () => {
                      let userData = {};

                      await useFetchingLoader(async () => {
                        userData = await http.get(
                          `users/getUserById/${successCreateModalPayload.id}`
                        );
                      });
                      userData = userData.response.data;
                      handleCloseSuccessCreateModal();
                      handleViewMore({
                        userId: userData.id,
                        Name: [userData.firstName, userData.lastName].join(' '),
                        firstName: userData.firstName,
                        lastName: userData.lastName,
                        employeeId: userData.employeeId,
                        'Card Number': userData.cardId,
                        Status: UserStatuses.find(
                          (el) => el.id === userData.userStatusId
                        )?.display,
                        kitchensIds: Kitchens?.filter((obj) =>
                          userData?.kitchens.some((el) => el.id === obj.id)
                        ).map((obj) => obj.id),
                        'Registration Date':
                          userData.createdAt?.split('T')[0] ?? undefined,
                        Kitchens: Kitchens?.filter((obj) =>
                          userData?.kitchens.some((el) => el.id === obj.id)
                        )
                          .map((obj) => obj.name)
                          ?.join(','),
                        Email: userData.email,

                        Role: Roles.find((el) => el.id === userData.roleId)
                          ?.display,
                        RoleId: userData.roleId,
                        Contractor: (contractorList
                          ? contractorList.find(
                              (el) => el.id === userData.contractors[0]?.id
                            )
                          : {}
                        )?.id,
                        allContractorInfo: contractorList
                          ? contractorList.find(
                              (el) => el.id === userData.contractors[0]?.id
                            )
                          : {},
                        allContractors: contractorList
                          ? contractorList.filter((obj1) =>
                              userData.contractors.some(
                                (obj2) => obj2.id === obj1.id
                              )
                            )
                          : [],
                        contractors: userData.contractors || null,
                        phone: userData.phone?.split(' ').join(''),
                        contractorName: userData?.contractorName,
                        StatusVal: UserStatuses.find(
                          (el) => el.id === userData.userStatusId
                        )?.value,
                        creditCard: userData.creditCard,
                        connectTeam: userData.connectTeam,
                        paymentMethod: [
                          ...new Set(
                            userData?.kitchens.map(
                              (el) => el.kitchenPayment.paymentOption.name
                            )
                          ),
                        ],
                        amount: userData?.userWallet?.wallet?.amount,
                        walletId: userData?.userWallet?.walletId,
                        totalReservation: userData.totalReservations,
                        updatedAt:
                          userData.updatedAt?.split('T')[0] ?? undefined,
                        magneticStripe: userData?.magneticStripe,
                        hireFrom: userData?.hireFrom,
                        hireTill: userData?.hireTill,
                        mealTypeId:
                          userData?.guestUser?.mealTypeId &&
                          userData?.guestUser?.mealTypeId !== 'undefined'
                            ? JSON.parse(userData?.guestUser?.mealTypeId)
                            : [],
                        generatedLink: userData?.guestUser?.generatedLink,
                        factoryId: userData?.factoryId,
                        isUsed: userData?.isUsed,
                        isPasswordTemporary: userData?.isPasswordTemporary,
                        company: userData?.guestUser?.company,
                        visitorCountry: userData?.guestUser?.visitorCountry,
                        visitPurpose: userData?.guestUser?.visitPurpose,
                        guestId: userData?.guestUser?.guestId,
                        isMultiOrders: userData?.isMultiOrders,
                        invitedBy: userData?.guestUser?.invitedBy,
                        secondRole: userData?.secondRole,
                      });
                    }}
                  >
                    {t('showUserCard')}
                  </button>
                  {!successCreateModalIsEdit ? (
                    <button
                      className="user-create-success-add"
                      onClick={async () => {
                        let userData = {};
                        await useFetchingLoader(async () => {
                          userData = await http.get(
                            `users/getUserById/${successCreateModalPayload.id}`
                          );
                        });
                        userData = userData.response.data;
                        const kitchensIds = Kitchens?.filter((obj) =>
                          userData?.kitchens.some((el) => el.id === obj.id)
                        ).map((obj) => obj.id);
                        let ids = kitchensIds;
                        let newMealTypeDateArray = [];
                        if (kitchensIds?.length) {
                          kitchensIds?.forEach((el) => {
                            let newMealTypeInfo = getMealTypes?.filter(
                              (elInfo) => elInfo?.kitchenId === el
                            );
                            newMealTypeDateArray = [
                              ...newMealTypeDateArray,
                              ...newMealTypeInfo,
                            ];
                          });
                        }
                        handleCloseSuccessCreateModal();
                        setMealTypesKitchenWise(newMealTypeDateArray);
                        setKitchensIds(ids);
                        if (userData['RoleId'] === 6) {
                          setShowContractor(true);
                        }
                        setPayload({
                          kitchensIds: ids,
                          role: [userData.roleId],
                          contractors: userData.contractors,
                          status: UserStatuses.find(
                            (el) => el.id === userData.userStatusId
                          )?.display,
                          magneticStripe:
                            userData.roleId === 7 || userData.roleId === 6
                              ? userData?.magneticStripe
                              : '',
                          hireFrom: userData['hireFrom'],
                          hireTill: userData['hireTill'],
                          mealTypeId:
                            userData?.guestUser?.mealTypeId &&
                            userData?.guestUser?.mealTypeId !== 'undefined'
                              ? JSON.parse(userData?.guestUser?.mealTypeId)
                              : [],
                          isPasswordTemporary: userData?.isPasswordTemporary,
                          mealTypesIds:
                            userData?.guestUser?.mealTypeId &&
                            userData?.guestUser?.mealTypeId !== 'undefined'
                              ? JSON.parse(userData?.guestUser?.mealTypeId)
                              : [],
                          company: userData?.guestUser?.company,
                          visitorCountry: userData?.guestUser?.visitorCountry,
                          visitPurpose: userData?.guestUser?.visitPurpose,
                          guestId: userData?.guestUser?.guestId,
                          isMultiOrders: userData?.isMultiOrders,
                          invitedBy: userData?.guestUser?.invitedBy,
                          secondRole: userData?.secondRole,
                        });
                        let mealTypeIdList = userData['mealTypeId'] || [];
                        let array = [];
                        if (mealTypeIdList?.length) {
                          let aa = mealTypeIdList?.map((el) => array.push(el));
                        }
                        setMealTypesIds(array);
                        handleOpen('recreate');
                      }}
                    >
                      {t('createAnotherUser')}
                    </button>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </Typography>
          </Box>
        </Modal>
      </div>
    </div>
  );
};

const ViewMore = ({ e, handleViewMore }) => {
  return (
    <div className="view-more-btn">
      <img src={next} alt="next" onClick={() => handleViewMore(e.data)} />
    </div>
  );
};
const CreditCard = ({ e }) => {
  return (
    <div className={`credit-card-option`}>
      <input type="checkbox" checked={e?.data?.creditCard} />
    </div>
  );
};
const PaymentMethod = ({ e, handleWallet, t }) => {
  let paymentMethodsForCA = '';
  if (e.data?.RoleId === 7) {
    if (
      e.data.allContractors &&
      e.data.allContractors.length !== 0 &&
      Boolean(e.data.allContractors.find((elem) => elem?.isInvoice === 1))
    ) {
      const methods = e.data.paymentMethod;
      methods.push('invoice');
      const uniqueArray = Array.from(new Set(methods));
      paymentMethodsForCA = uniqueArray.map((el) => t(el)).join(', ');
    } else {
      paymentMethodsForCA = e.data.paymentMethod.map((el) => t(el)).join(', ');
    }
  }
  return (
    <div
      className={`payment-method ${
        e.data['walletId'] ? 'without-link' : 'add-link'
      }`}
    >
      {e.data.paymentMethod.length
        ? (e.data?.RoleId !== 6 && e.data?.RoleId !== 8) ||
          (e.data?.RoleId === 8 &&
            e.data?.allContractorInfo &&
            e.data?.allContractorInfo?.isInvoice === 0) ||
          (e.data?.RoleId === 6 &&
            e.data?.allContractorInfo &&
            e.data?.allContractorInfo?.isInvoice === 0)
          ? e.data.paymentMethod
              ?.filter((el) => el !== 'invoice')
              .map((el, i, arr) =>
                el === 'Wallet' ? (
                  e.data['walletId'] ? (
                    <p
                      role="presentation"
                      onClick={() => handleWallet(e.data['walletId'])}
                    >
                      {t('wallet')}
                      <>
                        ({e.data.amount ? e.data.amount : 0})
                        {arr.length === 1 || arr.length - 1 === i ? ' ' : ', '}
                      </>
                    </p>
                  ) : el === 'Wallet' ? (
                    t('wallet') +
                    '(0)' +
                    (arr.length === 1 || arr.length - 1 === i ? ' ' : ', ')
                  ) : (
                    ' '
                  )
                ) : el === 'Salary' ? (
                  t('paymentOption-salary') +
                  (arr.length === 1 || arr.length - 1 === i ? '' : ', ')
                ) : el === 'Cibus' ? (
                  t('paymentOption-cibus') +
                  (arr.length === 1 || arr.length - 1 === i ? '' : ', ')
                ) : el === 'Credit Card' ? (
                  t('paymentOption-creditCard') +
                  (arr.length === 1 || arr.length - 1 === i ? '' : ', ')
                ) : el === 'Invoice' ? (
                  t('invoice')
                ) : (
                  '--'
                )
              )
          : e.data.allContractors &&
              e.data.allContractors.length !== 0 &&
              Boolean(
                e.data.allContractors.find((elem) => elem?.isInvoice === 0)
              )
            ? paymentMethodsForCA
            : t('invoice')
        : '--'}
    </div>
  );
};
const OrderLink = ({ e, handleOrderLink }) => {
  return (
    <div
      className={`payment-method ${
        e.data['userId'] != undefined ? 'add-link' : 'without-link'
      }`}
    >
      <p
        role="presentation"
        onClick={() =>
          handleOrderLink(e.data['userId'], e.data['Registration Date'])
        }
      >
        {/* Order Link */}
        {e.data['totalReservation']}
      </p>
    </div>
  );
};

const ActionButton = ({
  e,
  handleOpen,
  setPayload,
  setUpdate,
  setIsEdit,
  t,
  handleDeleteUser,
  kitchensIds,
  setKitchensIds,
  Kitchens,
  Roles,
  UserStatuses,
  ResendVerification,
  CopyDataUrl,
  handleConfirmation,
}) => {
  setUpdate(e.data);
  setIsEdit(true);
  return (
    <div
      className={`action-btn ${
        e.data.Status === 'Created' || e.data.Status === 'Pending'
          ? 'action-btn'
          : ''
      } `}
    >
      <button
        onClick={() => {
          let ids = [];
          if (e.data['Kitchens'].length) {
            Kitchens.forEach((el) => {
              if (el.name === e.data['Kitchens']) {
                ids.push(el.id);
              }
            });
          }
          setKitchensIds(ids);
          setPayload({
            userId: e.data['userId'],
            card: e.data['Card Number'],
            email: e.data['Email'],
            fName: e.data['firstName'],
            lName: e.data['lastName'],
            phone: e.data['phone']
              ? e.data['phone'].split('-').join('')
              : e.data['phone'],
            kitchensIds: ids,
            employeeId: e.data['employeeId'],
            role: [Roles.find((el) => el.display === e.data['Role'])?.id],
            contractors: e.data['contractors'] ? e.data['contractors'] : null,
            contractorId:
              Roles.find((el) => el.display === e.data['Role'])?.id == 4
                ? null
                : e.data['Contractor'],
            // status: UserStatuses?.find(el => el.display ===e.data["Status"])?.id
            status: e.data['Status'],
            magneticStripe: e.data['magneticStripe'],
            hireFrom: e?.data['hireFrom'],
            hireTill: e?.data['hireTill'],
            mealTypeId: e?.data['mealTypeId'],
            isPasswordTemporary: e.data['isPasswordTemporary'],
            company: e?.data['company'],
            visitorCountry: e?.data['visitorCountry'],
            visitPurpose: e?.data['visitPurpose'],
            guestId: e?.data['guestId'],
            isMultiOrders: e?.data['isMultiOrders'],
            secondRole: e?.data['secondRole'],
          });
          let mealTypeIdList = JSON.parse(e.data['mealTypeId']);
          let array = [];
          if (mealTypeIdList.length) {
            let aa = mealTypeIdList.map((el) => array.push(el));
          }
          setMealTypesIds(array);
          handleOpen();
        }}
        className="edit-btn"
      >
        {t('editbutton')}
      </button>
      <button
        className="delete-btn"
        // onClick={() => handleDeleteUser(e.data['userId'])}
        onClick={() => handleConfirmation(e.data['userId'])}
      >
        {t('deletebutton')}
      </button>
      {UserStatuses?.find((el) => el.display === e.data['Status'])?.value ===
        'created' ||
      UserStatuses?.find((el) => el.display === e.data['Status'])?.value ===
        'pending' ? (
        <button
          className="resend-btn"
          onClick={() => ResendVerification(e.data)}
        >
          {t('resend')}
        </button>
      ) : (
        ''
      )}
      {UserStatuses?.find((el) => el.display === e.data['Status'])?.value ===
        'created' ||
      UserStatuses?.find((el) => el.display === e.data['Status'])?.value ===
        'pending' ? (
        <button className="copy-btn" onClick={() => CopyDataUrl(e.data)}>
          {t('copyUrl')}
        </button>
      ) : (
        ''
      )}
      <div className="menu-item">
        <div
          // onClick={() => handleDeleteUser(e.data['userId'])}
          onClick={handleViewMore}
        >
          {t('viewMore')}
        </div>
      </div>
    </div>
  );
};

const NameCellRender = ({ e }) => {
  return (
    <div className="name-section">
      <h6>
        <p>{e?.data?.Name ? e?.data?.Name : '--'}</p>
      </h6>
      <p>{e?.data?.Email ? e?.data?.Email : '--'}</p>
    </div>
  );
};

const CardIdCellRender = ({ e }) => {
  return (
    <div className="name-section">
      <p>
        {e?.data?.['Card Number']
          ? e?.data['Card Number']?.toString()?.padStart(9, '0')
          : '--'}
      </p>
    </div>
  );
};

const EmployeeIdCellRender = ({ e }) => {
  return (
    <div className="name-section">
      <p>{e.data.employeeId ? e?.data?.employeeId : '--'}</p>
    </div>
  );
};
const PhoneCellRender = ({ e }) => {
  return (
    <div className="name-section">
      <p>{e.data.phone ? e?.data?.phone : '--'}</p>
    </div>
  );
};
const ContractorCellRender = ({ e }) => {
  return (
    <div className="name-section">
      <p>
        {e.data.contractors
          ? e?.data?.contractors.map((elem) => elem.name).join(', ')
          : '--'}
      </p>
    </div>
  );
};
const KitcheCellRender = ({ e }) => {
  return (
    <div className="name-section">
      <p>{e?.data?.Kitchens?.length > 0 ? e?.data?.Kitchens : '--'}</p>
    </div>
  );
};

const StatusCellRender = ({ e, t, Roles }) => {
  return (
    <div className="name-section">
      <Tooltip
        title={`${e?.data?.Role === 'Client' ? 'Employee' : e?.data?.Role}, ${
          Roles && e?.data?.secondRole
            ? Roles.find((el) => el.id === e?.data?.secondRole)?.display
            : ''
        }`}
        disableHoverListener={!e?.data?.secondRole}
        disableFocusListener={!e?.data?.secondRole}
        disableTouchListener={!e?.data?.secondRole}
      >
        <p>
          {`${e?.data?.Role === 'Client' ? 'Employee' : e?.data?.Role} ${
            e?.data?.secondRole ? '+' : ''
          }`}
        </p>
      </Tooltip>
      <h6
        className={`user-status ${
          e?.data?.StatusVal === 'active' ? 'status-active' : ''
        } ${e?.data?.StatusVal === 'deleted' ? 'status-deleted' : ''}`}
      >
        <i class="fa fa-circle" aria-hidden="true"></i> {e?.data?.Status}
      </h6>
    </div>
  );
};
const PopupCellRenderer = ({
  e,
  handleOpen,
  setPayload,
  setUpdate,
  setIsEdit,
  t,
  handleDeleteUser,
  kitchensIds,
  setKitchensIds,
  Kitchens,
  Roles,
  UserStatuses,
  ResendVerification,
  CopyDataUrl,
  handleConfirmation,
  setShowContractor,
  contractorList,
  handleViewMore,
}) => {
  const tippyRef = useRef();
  const [visible, setVisible] = useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);

  const dropDownContent = (
    <div
      className={`action-btn ${
        e.data.Status === 'Created' || e.data.Status === 'Pending'
          ? 'action-btn'
          : ''
      } `}
      style={{ position: 'relative' }}
    >
      <div className="menu-container">
        <div className="menu-item">
          <div
            onClick={() => {
              let ids = [];
              if (e.data['Kitchens'].length) {
                Kitchens.forEach((el) => {
                  if (el.name === e.data['Kitchens']) {
                    ids.push(el.id);
                  }
                });
              }
              setKitchensIds(ids);
              if (e.data['RoleId'] === 6) {
                setShowContractor(true);
              }

              setPayload({
                userId: e.data['userId'],
                card: e.data['Card Number'],
                email: e.data['Email'],
                fName: e.data['firstName'],
                lName: e.data['lastName'],
                phone: e.data['phone']
                  ? e.data['phone'].split('-').join('')
                  : e.data['phone'],
                kitchensIds: ids,
                employeeId: e.data['employeeId'],
                contractors: e.data['contractors']
                  ? e.data['contractors']
                  : null,
                role: [Roles.find((el) => el.display === e.data['Role'])?.id],
                contractorId:
                  Roles.find((el) => el.display === e.data['Role'])?.id == 4
                    ? null
                    : e.data['Contractor'],
                // status: UserStatuses?.find(el => el.display ===e.data["Status"])?.id
                status: e.data['Status'],
                mealTypeId: e?.data['mealTypeId'],
                magneticStripe: e.data['magneticStripe'],
                isPasswordTemporary: e.data['isPasswordTemporary'],
                company: e?.data['company'],
                visitorCountry: e?.data['visitorCountry'],
                visitPurpose: e?.data['visitPurpose'],
                guestId: e?.data['guestId'],
                isMultiOrders: e?.data['isMultiOrders'],
                secondRole: e?.data['secondRole'],
              });
              handleOpen();
            }}
          >
            {t('editbutton')}
          </div>
        </div>
        <div className="menu-item">
          <div
            // onClick={() => handleDeleteUser(e.data['userId'])}
            onClick={() => handleConfirmation(e.data['userId'])}
          >
            {t('deletebutton')}
          </div>
        </div>

        {UserStatuses?.find((el) => el.display === e.data['Status'])?.value ===
          'created' ||
        UserStatuses?.find((el) => el.display === e.data['Status'])?.value ===
          'pending' ? (
          <div className="menu-item">
            <div
              className="resend-btn"
              onClick={() => ResendVerification(e.data)}
            >
              {t('resend')}
            </div>
          </div>
        ) : (
          ''
        )}

        {UserStatuses?.find((el) => el.display === e.data['Status'])?.value ===
          'created' ||
        UserStatuses?.find((el) => el.display === e.data['Status'])?.value ===
          'pending' ? (
          <div className="menu-item">
            <div onClick={() => CopyDataUrl(e.data)}>{t('copyUrl')}</div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );

  const onClickHandler = (option) => {
    hide();
    if (option === 'create') {
      props.api.applyTransaction({
        add: [{}],
      });
    }
    if (option === 'delete') {
      props.api.applyTransaction({ remove: [props.data] });
    }

    if (option === 'edit') {
      props.api.startEditingCell({
        rowIndex: props.rowIndex,
        colKey: 'make',
      });
    }
  };

  return (
    <Tippy
      ref={tippyRef}
      content={dropDownContent}
      visible={visible}
      onClickOutside={hide}
      allowHTML={true}
      arrow={false}
      appendTo={document.body}
      interactive={true}
      placement="right"
    >
      <img src={more} onClick={visible ? hide : show} className="more-info" />
      {/* <i
        className="fa fa-ellipsis-v"
        aria-hidden="true"
        onClick={visible ? hide : show}
      ></i> */}
    </Tippy>
  );
};
const mapStateToProps = (state) => ({
  kitchen: state.kitchens.current,
  user: state.user,
  Roles: state.app.enums.Role,
  showFetchingLoader: state.app.showFetchingLoader,
  UserStatuses: state.app.enums.UserStatus,
  Kitchens: state.kitchens.options,
  languages: state.languages.options,
  CurrentLanguage: state.languages.current,
  allUsers: state.user.allUsers,
  usersTotal: state.user.usersTotal,
  allUserData: state.user.allUserData,
  cibusEnabled: state.companyConfig.paymentsSettings.cibusEnabled,
  attendanceEnabled: state.companyConfig.menuSettings.attendanceEnabled,
  companyId: state.companyConfig.companyId,
  employeeNumberLengthForUser: state.companyConfig.employeeNumberLengthForUser,
  cardNumberLengthForUser: state.companyConfig.cardNumberLengthForUser,
  cardGenerationUrlForUser: state.companyConfig.cardGenerationUrlForUser,
  companyName: state.companyConfig.companyName,
  isStrippedCard: state.companyConfig.isStrippedCard,
  fieldNamesSettings: state.companyConfig.fieldNamesSettings,
});

const mapDispatchToProps = {
  setLayoutOptions,
  useLoader,
  useFetchingLoader,
  showAlert,
  getAllUser,
  getUserConfigByCompanyId,
};
export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);
