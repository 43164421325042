import { ITranslationStrings } from './interface';

const translations: ITranslationStrings = {
  //@ts-ignore
  translation: {
    poweredBy: 'Поддерживается с помощью',
    /* Sidebar translations */
    // Client
    orderMeal: 'Заказать еду',
    fastVoucher: 'Ваучер на питание',
    noFutureVoucher: 'Нет будущего ваучера',
    reviewOrder: 'Просмотреть заказ',
    noOrderToReview: 'Нет заказа для просмотра',
    myMeals: 'Мои приемы пищи',
    choosingMealTime: 'Выберите дату еды',
    chooseDishForMeal: 'Выберите Блюдо',
    chooseDishFor: 'Выберите блюдо для',
    chooseDish: 'Выберите Блюдо',
    changeDishFor: 'Сменить блюдо на',
    changeDish: 'Сменить блюдо',
    userProfile: 'Профиль пользователя',
    error: 'Ошибка',
    logout: 'Выйти',
    forgotPassword: 'Забыли пароль?',

    // Kitchen
    mealManagement: 'Управление питанием',
    reservationManagement: 'Управление бронированием',
    dishManagement: 'Управление блюдами',
    tagManagement: 'Управление тегами',
    ordersHistory: 'История заказов',
    Settings: 'Настройки',
    dishDelivery: 'Доставка блюд',
    textWithEmoji: 'Ваш текст содердит неподдерживаемые символы (эмодзи)',
    registerSuccessfully: 'Регистрация прошла успешно',
    profileUpdated: 'Профиль обновлен',
    orderVoucher: 'Заказать ваучер',
    nextMeal: 'Следующий прием пищи',
    nextMeals: 'Следующие приемы пищи',
    noInfo: 'Информация отсутствует',
    noRating: 'Рейтинг отсутствует',
    mealRanOut: 'Блюда закончились',
    toOrder: 'Заказать',
    lastOrder: 'Последний заказ',
    selectDishType: 'Выберите тип блюда',
    selectMealType: 'Выберите тип питания',
    mealType: 'Приём пищи',
    selectMealDate: 'Выберите дату приема пищи',
    addReview: 'Добавить отзыв',
    noOrdersToDisplay: 'Заказов нет, пора заказать первую еду!',
    lobby: 'Главный экран',
    hello: 'Привет',
    confirm: 'Подтвердить',
    signup: 'Зарегистрироваться',
    resetPassword: 'Сбросить пароль',
    orderSummaryTitle: 'Информация о заказе',
    loginTitle: 'Войти',
    addReviewTitle: 'Добавить отзыв',
    backToDishSelection: 'Вернуться к выбору блюд',
    dishSelection: 'Выбор блюд',
    changeOrder: 'Изменить заказ',
    orderConfirmation: 'Подтверждение заказа',
    noMeals: 'На указанную дату нет доступных блюд',
    noAvailableMeals: 'Для выбранных фильтров нет доступных блюд',
    rateUsingStars: 'Оцените это блюдо',
    writeDishOpinion: 'Оставьте свой отзыв (необязательно)',
    sendReview: 'Добавить отзыв',
    additionalInfo: 'Больше информации',
    orderReceivedSuccessfully: 'Заказ был успешно получен',
    viewVoucher: 'Просмотреть ваучер',
    cancelOrder: 'Отмена',
    orderNotRealized: 'Заказ не реализован',
    orderNotActive: 'Заказ не активен',
    dishIsOrdered: 'Заказанное блюдо',
    chooseAnotherDish: 'Выберите другое блюдо',
    backToMyMeals: 'Вернуться к моим блюдам',
    backToMealConstruction: 'Вернуться к управлению меню',
    backToDishManagement: 'Вернуться к управлению блюдом',
    backToMealManagement: 'Вернуться к управлению питанием',
    realizedOrders: 'Реализованные заказы',
    ordersFromInventory: 'Заказы из инвентаря',
    alreadyRealizedForDate: 'Уже заказано на эту дату',

    realizedOrder: 'Реализованныей заказ',
    realizeOrder: 'Реализовать заказ',

    // Form placeholders And Labels
    emailPH: 'Электронное письмо',
    emailLoginPH: 'Имя пользователя',
    namePH: 'Имя',
    passwordPH: 'Пароль',
    repeatPasswordPH: 'Повторите пароль',
    updatePassword: 'Обновить пароль',
    rolePH: 'Роль',
    kitchenPH: 'Кухня',
    firstNamePH: 'Имя',
    lastNamePH: 'Фамилия',
    phonePH: 'Телефонный номер',
    cardIdPH: 'Номер карты',
    employeeIdPH: 'Число работников',
    employeeIdOrEmail: 'Email',
    connectLabel: 'Соединять',
    submitLabel: 'Отправить',
    keepMeLoggedLabel: 'Не выходить из системы',
    iWantToReceiveViaMail:
      'Я хочу получать по электронной почте подтверждения заказа',
    saveInfo: 'Сохранить',
    category: 'Категория',
    name: 'Имя',
    shortDescription: 'Краткое описание',
    cancel: 'Отмена',
    raters: 'Отзывы',
    loginWithOtp: 'Войти с однозаровым кодом',
    labelSendOtp: 'Как бы вы хотели получить код',
    sendEmailOtp: 'Пришлите мне код',
    labelEnterOtp: 'Код',
    placeholderOtp: '123456',
    verify: 'Подтвердить код',
    or: 'Или',

    // Kitchen Management
    homeMenu: 'Домой',
    controlPanel: 'Панель управления',
    addClientUser: 'Добавить пользователя',
    defineNewMeal: 'Добавить новый прием пищи',
    mealReport: 'Отчет о приеме пищи',
    nextMealToDefine: 'Определить следующий прием пищи',
    addDish: 'Добавить блюдо',
    editDish: 'Редактировать блюдо',
    edit: 'Редактировать',
    manageDishes: 'Управление блюдами',

    showPreviousMeal: 'Показать предыдущий прием пищи',
    showPreviousDates: 'Показать предыдущие даты',
    noFutureDates: 'Нет будущих дат',

    menuConstruction: 'Состав меню',
    orders: 'Резервации',
    dishesInMeal: 'Блюда в приеме пищи',
    inventory: 'Количество',
    delete: 'Удалить',
    addImage: 'Добавить изображение',
    date: 'Дата',
    type: 'Тип',

    addDishToMeal: 'Добавить блюдо в прием пищи',
    includedInMeal: 'Включено в прием пищи',
    noDishes: 'Нет блюд для отображения',
    moreInfo: 'Больше информации',
    addMeal: 'Добавить прием пищи',

    open: 'Открыть',
    hide: 'Скрыть',
    toOrders: 'Перейти к заказам',
    dishes: 'Блюда',

    // Control Panel
    previousMeal: 'Предыдущий прием пищи',
    mealSegmentation: 'Разделение питания',
    moreMeals: 'Больше приемов пищи',

    dishesRanOutOutOf: 'Этих блюд больше нет',

    numOfReviews: 'Количество отзывов',
    averageScore: 'Средний счет',

    dishesRatingInTheLast: 'Рейтинг блюд за последний',
    popularMealInTheLast: 'Популярные блюда за последние',
    days: 'дней',

    noInventoryInMeal: 'Нет блюд в приеме пищи',

    userManagement: 'Управление пользователями',
    addUser: 'Добавить пользователя',
    enterUserDetails: 'Введите данные пользователя',
    noUsers: 'Нет пользователей для отображения',
    systemUsers: 'Пользователи системы',

    // Validators
    invalidEmail: 'Неверный адрес электронной почты',
    invalidID: 'Неверный ID',
    invalidPhoneNumber: 'Неправильный номер',
    enterNameInHebrew: 'Введите имя на иврите',
    requiredField: 'Обязательное поле',
    dateError: 'Начальная дата не может быть больше конечной',
    passMinLength: 'Используйте не менее 8 символов',
    passwordsDontMatch: 'Поля пароля не совпадают',

    // Swal Alerts
    back: 'Назад',
    removeDishTitle: 'Удалить название блюда',
    removeDishBtnLabel: 'Удалить лейбл блюда',
    areYouSureYouWantToRemoveDish: 'Вы уверены что хотите удалить блюдо?',
    cancelOrderTitle: 'Отменить заказ',
    orderWasCanceled: 'Заказ был отменен',
    ordersWasCanceled: 'Заказы были отменены',
    cancelOrderBtnLabel: 'Отменить заказ',
    areYouSureYouWantToCancelOrder: 'Вы уверены, что хотите удалить заказ?',
    replaceOrderBtnLabel: 'Заменить заказ',
    areYouSureYouWantToReplaceOrder: 'Вы уверены, что хотите заменить заказ?',
    selectAnotherDishBtnLabel: 'Выбрать другой',
    areYouSureYouWantToSelectAnotherDish:
      'Вы уверены, что хотите выбрать другое блюдо?',
    orderExistsTitle: 'Заказ уже существует',
    enterEmailTitle: 'Введите почту',
    enterEmailText:
      'Введите свой адрес электронной почты, чтобы получить ссылку для сброса',
    sendViaEmail: 'Отправить по электронной почте',

    resetPasswordTitle: 'Сброс пароля',
    resetPasswordText: 'Проверьте ссылку в своем электронном письме',
    resetPasswordTextPhone:
      'Мы отправили вам сообщение с подробностями восстановления',
    passwordUpdatedTitle: 'Пароль успешно обновлен',
    passwordUpdatedText:
      'Теперь вы можете использовать свой новый пароль для входа в систему',
    passwordUpdatedConfirmButtonText: 'Вернуться к авторизации',

    otpEmailSuccess:
      'Если адрес электронной почты существует, вам было отправлено сообщение с одноразовым кодом входа',
    otpPhoneSuccess:
      'Если номер телефона существует, мы отправили вам сообщение с одноразовым кодом доступа',
    otpAddAtleastOneField:
      'Адрес электронной почты и номер мобильного телефона не могут быть пустыми',

    // New translations 11/4/21
    inventoryLessThanOrdered:
      'Подготовленных блюд не может меньше чем заказанных',
    inventoryLessThanZero: 'Приготовленных блюд не может быть меньше нуля',
    returnToMyMeals: 'Вернуться к моим приемам пищи',
    returnToDishSelection: 'Вернуться к выбору блюд',
    returnToChangeDish: 'Вернуться для замены блюда',
    returnToDishManagement: 'Вернуться к управлению блюдами',
    returnToMealManagement: 'Вернуться к управлению приемами пищи',
    returnMenuConstruction: 'Вернуться к созданию меню',

    noMealsForThisDate: 'Нет приемов пищи на эту дату',

    // New translations 5/5/21
    imagesForIllustration: 'Изображения только для иллюстрации',
    noFutureMeal: 'Нет приемов пищи на будущее',
    firstTimeHere: 'Впервые здесь? Перейти к подписке >>',
    loginDetails: 'Данные для входа',
    identifyByEmployeeId: 'Войти с идентификатором сотрудника',
    identifyByEmail: 'Войти по электронной почте',

    // New translations 12/5/21
    thereIsAlreadyAnOrder: 'Заказ на это блюдо уже существует',
    signupFormExplanation: 'Тут вы можете настроить свои данные',
    noNextMeal: 'Новых приемов пищи не планируется',

    // New translations 12/7/21
    enterInventoryFor: 'Введите количество для',

    // New translations 29/9/21
    welcomeToWorkeat: 'Добро пожаловать в WorkEat',
    theWayTo: 'the way to eat at work',
    setPasswordDescription: `и снова здравствуйте, Мы рады, что вы пришли.`,

    resetPasswordDescription: `Пожалуйста, выберите новый пароль`,
    // New translation 14/10/21
    permissionsError: 'Вы были отключены от системы, пожалуйста выполните вход',

    // TODO - needs translations / check for accuracy
    genericError: 'Неизвестная ошибка',
    cantDeleteMealWithOrders: 'Невозможно удалить прием пищи с заказами',
    removeMealTitle: 'Удалить прием пищи',
    removeMealBtnLabel: 'Удалить',
    areYouSureYouWantToRemoveMeal:
      'Нажатие кнопки «Удалить блюдо» приведет к удалению блюда из ??',
    loadingLabel: 'WorkEat',
    returnToMenuConstruction: 'вернуться к формированию заказа',
    returnToMealReport: 'Вернуться к информации о приеме пищи',
    realized: 'Реализовано',
    chefDish: 'блюдо от шеф-повара',
    addKitchen: 'добавить кухню',
    kitchensManagement: 'Управление кухней',
    employeeManagement: 'Управление персоналом',
    lock: 'Блокировать',
    unlock: 'Разблокировать',
    noKitchens: 'Нет доступных кухонь',
    systemKitchens: 'Системные кухни',
    close: 'Закрыть',
    enterKitchenDetails: 'Введите информацию о кухне',
    active: 'активный',
    notActive: 'не активен',
    statusPH: 'Статус',
    registrationDatePH: 'Дата регистрации',
    filterByStatus: 'Фильтр по статусу',
    statusesPH: 'статус',
    blocked: 'Заблокировано',
    kitchensPH: 'Кухни',
    deleteUserTitle: 'Удалить пользователя',
    deleteUserBtnLabel: 'Удалить пользователя',
    areYouSureYouWantToDeleteUser:
      'Вы уверены, что хотите удалить ползователя?',
    tempPasswordPH: 'Временный пароль',
    setPassword: 'Установка пароля',
    employeeWasAdded: 'Сотрудник добавлен',

    paymentManagementButton: 'Управление оплатой',
    companyNumber: 'Номер компании',
    personalHashKey: 'Персональный хеш-ключ',
    merchantId: 'идентификатор продавца',
    'paymentOption-cibus': 'Сибус',
    'paymentOption-tenBis': '10bis',
    'paymentOption-creditCard': 'кредитная карта',
    'paymentOption-salary': 'зарплата',
    paymentManagementFormExplanation:
      'Пожалуйста, заполните следующие данные в соответствии с вашими предпочтениями и с панели инструментов NayaX.',
    paymentPreferncesUpdated: 'Настройки были обновлены.',

    price: 'Цена',
    orderPrice: 'Цена',
    positiveNumber: 'Требуется положительное число',
    needValidEmployeeIdOrEmail:
      'Требуется действующий номер сотрудника или адрес электронной почты.',

    reservedByString: 'Зарезервировано',
    reservedByFirstName: 'Имя',
    reservedByLastName: 'Фамилия',

    reservationDate: 'Дата заказа',
    reservationNumber: 'Номер заказа',

    reservationDetails: 'Подробности',
    reservationKitchen: 'Кухня',
    reservationMealType: 'Тип приема пищи',
    reservationMealDate: 'Дата приема пищи',
    reservationDish: 'Блюдо',
    reservationPayment: 'Оплата',
    reservationChannel: 'Канал / Источник',
    cardLastDigits: 'Последние цифры карты',

    addNewPaymentMethod: 'Добавить новый способ оплаты',
    successfullySavedPaymentMethod: 'Способ оплаты успешно сохранен.',
    errorSavingPaymentMethod:
      'Произошла ошибка при попытке сохранить способ оплаты. Попробуйте снова!',
    viewStoredPaymentMethods: 'Просмотр сохраненных способов оплаты',
    updateDefaultPaymentMethod: 'Обновить способ оплаты по умолчанию',

    employeeName: 'Имя',
    employeeNo: 'Внутренний #',
    cardNo: 'Номер карты',
    status: 'Статус',
    tag: 'Название тега',
    addTag: 'Добавить тег',
    returnToTagManagement: 'Вернуться к управлению тегами',
    isActive: 'Активен',
    isPublic: 'Публичный',
    selectTags: 'выберите теги',
    registrationDate: 'Зарегистрирован',
    email: 'Эл. адрес',
    kitchenname: 'Название кухни',
    action: 'Действие',
    downloadCSV: 'Скачать экспортный файл CSV',
    add: 'Добавить',
    pageSize: 'Размер страницы',
    editbutton: 'Редактировать',
    deletebutton: 'Удалить',
    filter: 'Фильтр...',
    role: 'Роль',
    userName: 'Имя пользователя',
    firstName: 'Имя',
    lastName: 'Фамилия',
    phone: 'Телефон',
    password: 'пароль',
    roles: 'Роли',
    update: 'Обновить',
    updateUser: 'Обновить пользователя',
    updateEmployee: 'Обновить сотрудника ',
    addUsers: 'Добавить пользователя',
    addEmployee: 'Добавить сотрудника',
    okay: 'OK',
    tagUpdatedSuccessfully: 'Тег обновлен успешно',
    cookieContent:
      'Наш веб-сайт использует файлы cookie, чтобы вам было удобнее. Мы предполагаем, что вы согласны с этим',
    acceptbtn: 'Принять',
    rejectbtn: 'Отказаться',
    createddate: 'Дата',
    companyName: 'Название компании',
    company: ' Название компании',
    generateSalaryFile: 'Сгенерировать файл заработной платы',
    totalInventory: 'Общий инвентарь',
    totalReservations: 'Всего резерваций',
    totalReservation: 'Всего резерваций',
    totalRealized: 'Всего реализовано',
    dishName: 'Название блюда',
    dishesInOrder: 'Название блюда',
    tagsname: 'Название тега',
    tInventory: 'Инвентарь',
    reservations: 'Резервации',
    tRealized: 'Реализованный',
    chef: 'Шеф-Повар',
    printReport: 'Напечатать отчет',
    kitchenName: 'Название кухни',
    mealtype: 'Тип питания',

    security: 'Безопасность',
    securityFormTitle: 'Предоставить менеджменту доступ к следующим IP адресам',
    securityFormBlacklistPlaceholder: 'Comma seperated IPv4 list',
    securitySubmitButton: 'Сохранить',
    ipv4FormatIncorrect: 'IPV4 формат некорректный!',
    securityFormSuccess: 'Мероприятия безопасности успешно обновлены',
    confirmModalTitle: 'Вы уверены?',
    //19-12-2021
    fromDate: 'С',
    toDate: 'По',
    orderId: 'номер заказа',

    clearDish: 'Очистить все блюда',
    updateInventory: 'Обновить инвентарь',
    copyMeal: 'Копировать из предыдущего приема пищи',
    clearText: 'Вы уверены, что хотите удалить отсюда все блюда?',

    selectSlot: 'Выбрать слот',
    preferedTime: 'Пожалуйста, выберите желаемое время',
    breakfast: 'Время завтрака',
    lunch: 'Время обеда',
    dinner: 'Время ужина',
    overtime: 'Сверхурочная работа',
    night: 'Ночное время',
    firstSeat: 'Первое место',
    secondSeat: 'Второе место',
    thirdSeat: 'Третье место',
    dishSelections: 'Перейти к выбору блюда',

    addMealtype: 'Добавить тип приема пищи',
    mealtypes: 'Типы приемов пищи',
    startTime: 'Начальное время',
    endTime: 'Время окончания',
    mealtypeSlot: 'Слот типа приема пищи',
    maxReservations: 'Максимальное количество резерваций',
    selectDate: 'Выберите дату',
    selectMealtype: 'Все типы приемов пищи',
    voucherExpired: 'Срок действия ваучера истек',
    mealTimeManagement: 'Управление типами приема пищи',
    maxReservationMessage: 'На данный момент мест больше нет',
    ok: 'ОК',
    extendalId: 'Внешний  ID',
    orderIndex: 'ID заказа',
    existTime: ' Это время уже существует',
    deleteMealTypeSlotError: 'Удалять можно только приемы пищи без заказов',
    deletedSuccessfully: 'Успешно удалено',
    editSuccessfully: 'Успешно отредактировано',
    addedSuccessfully: 'Успешно добавлено',
    orderIndexerror: 'OrderIndex должен быть уникальным',
    orderZeroError: 'orderIndex не может быть равен нулю',
    noSlotsAvailable: 'Нет доступных слотов',
    UpdateMaxReservations: 'Обновить максимальное количество резерваций',
    realizedOrderText: 'Заказ был реализован',

    /*******logs  */
    description: 'Описание',
    time: 'Время',
    controller: 'Контроллер',
    tableName: 'Имя Таблицы',
    updatedData: 'Обновленные данные',

    welcomeText: 'Добро пожаловать',
    mealAction: 'Действия с едой',
    send: 'Отправить',

    viewMore: 'Посмотреть больше',
    allRatingDish: 'Все оценки для этого блюда',
    reviews: 'Отзывы',
    comments: 'Комментарии',
    comment: 'Комментарий',
    addComment: 'Добавить комментарий',
    showdishes: 'Показать блюда',

    privacyPolicy: 'Политика конфиденциальности',
    termsOfUse: 'Условия эксплуатации',
    logViewer: 'Просмотр журнала',
    user: 'Пользователи',
    rating: 'Рейтинг',
    averageMeal: 'Средний балл по отзывам о еде',
    popuplar: 'Популярный',
    apply: 'Применить',
    noFastVoucher:
      'Une fois le prochain repas commencé, nous vous présenterons un lien vers votre code QR de réservation. Repas commencé et pas de lien ? Il est temps de passer une commande',
    day: 'День',
    noofDishes: 'Количество блюд',
    addPreviousDish: 'Добавить к предыдущему блюду',
    availableExtras: 'Доступные дополнения к еде',
    finalizeOrder: 'Завершить заказ',
    skip: 'Пропустить',
    extrameal: 'Дополнения к еде',
    addExtras: 'Добавить дополнения',
    paymentConfirm: 'Оплата была произведена картой с последними 4 цифрам:',
    paymentAlert:
      'Ваш профиль не имеет активного способа оплаты. Чтобы завершить заказ, необходимо добавить кредитную карту и начать процесс заново. Нажми на "добавить способ оплаты", чтобы перейти на страницу управления оплатой.',
    paymentInfo: 'Информация о платеже',
    addMethod: 'Добавить способ оплаты',
    extras: 'Дополнительно',
    none: 'Нет',
    slot: 'Слот',
    reservationExport: 'Резервирования экспортированы на',
    fromtime: 'От времени)',
    totime: 'Ко времени)',
    applyfilter: 'Применить фильтр',
    CopyFromPreviousDish: 'Скопировать из другого блюда',
    CopyMeal: 'Скопировать прием пищи',
    RedirectingYouToLoginPage: 'Перенаправляем на страницу входа',
    cancelOrderText: 'Нажмите на "подтверждаю" для отмены заказа',
    cancelOrderTextin: 'в',
    changeOrderText: 'Нажмите на "подтверждаю" для изменения',
    changeOrderTextto: 'на',
    passwordSecurity:
      'Длина пароля должна быть минимум 8 букв, минимум 1 заглавная буква, 1 строчная буква и 1 символ.',
    updatedMessage: 'Успешно Обновлено',
    addedmessage: 'Добавлено успешно',
    deleteMessage: 'Удалено успешно',
    currentPasswordError: 'Введите текущий пароль',
    chooseYourDish: 'Выберите свое блюдо',
    groupsManagement: 'Управление группами',
    contactPersonName: 'Имя контактного лица',
    contactEmail: 'Почта для связи',
    contactPhone: 'Контактный телефон',
    addContractor: 'Добавить группу',
    updateContractor: 'Обновить группу',
    contractor: 'Группа',
    voucherRealized: 'Ваучер уже реализован.',
    voucherNotDisplay: 'Ваучер не может быть отображен',
    profile: 'Профиль',
    scanVoucher:
      'Пожалуйста, отсканируйте этот QR, чтобы реализовать свой заказ.',
    paymentDetails: 'Детали оплаты',
    amountPaid: 'Выплаченная сумма',
    timeOfPayment: 'Время платежа',
    transactionId: 'ID транзакции',
    futureMeals: 'Текущее управление приемом пищи',
    pastMeals: 'Прошлые приемы пищи',
    reports: 'Отчеты',
    changePassword: 'Изменить пароль',
    oldPassword: 'Старый пароль',
    newPassword: 'Новый пароль',
    paymentManagement: 'Управление оплатой',
    selectPaymentOption: 'Выберите вариант оплаты',
    selectCurrency: 'Выберите валюту',
    realizedStatus: 'Статус реализации',
    contractorName: 'Имя группы',
    selectContractor: 'Выберите группу',
    allContractor: 'Все группы',
    alertForContractor:
      'Вы не можете заказать еду, потому что у вас нет кухни.',
    realizedDate: 'Дата реализации',
    realizedMessage: 'Бронирование уже реализовано на',
    at: 'в',
    webapp: 'Веб-приложение',
    dispenser: 'Автомат выдачи',
    resetPasswordExpired:
      'Ссылка для сброса пароля недействительна или уже использовалась. При необходимости отправьте запрос на сброс пароля еще раз.',

    character: 'Символ ?',
    isNotVaild: 'Пароль не валиден -',
    inCorrectPassword: 'Неверный пароль',
    contractorEmployeeManagement: 'Пользователи группы',
    addContractorEmployee: 'Добавить сотрудника группы',
    updateContractorEmployee: 'Обновить сотрудника группы',
    contractorId: 'Идентификатор группы',
    userWalletManagement: 'Управление пользовательским кошельком',
    creditDate: 'Дата кредита',
    creditAmount: 'Сумма кредита',
    debitAmount: 'Сумма дебета',
    debitDate: 'Дата дебета',
    remainingAmount: 'остаток средств',
    wallet: 'Кошелек',
    chooseCurrency: 'Пожалуйста, выберите валюту',
    cancellationIsAvaiableUntill: 'Отмена доступна до',
    ofTheSame:
      'того же дня, что и еда, за бронирование, которое не будет отменено или погашено, будет взиматься плата',
    cancellationTime: 'Время отмены',
    multipleDish: 'Несколько блюд',
    creditPoints: 'Кредитные баллы',
    orderMealHeading: 'ЗАКАЗАТЬ ЕДУ',
    continue: 'Продолжить',
    my: 'Мой',
    order: 'Заказ',
    chooseADishToContinue: 'Выберите блюдо, чтобы Продолжить',
    totalAmount: 'Итого',
    viewOrder: 'Посмотреть заказ',
    orderDate: 'Дата заказа',
    paymentMethod: 'Способ оплаты',
    totalData: 'Всего данных',
    dish: 'Блюдо',
    product: 'товар',
    extra: 'экстра',
    quantity: 'Количество',
    amount: 'Кол-во',
    mealDate: 'Дата приема пищи',
    myOrders: 'мои заказы',
    voucher: 'Ваучер',
    selectMealTypeSecond: 'Выберите тип питания',
    menu: 'меню',
    Category: 'Категория',
    accountSettings: 'Настройки учетной записи',
    account: 'Аккаунт',
    password1: 'Пароль',
    payment: 'Оплата',
    save: 'Сохранить',
    passwordSettings: 'Настройки пароля',
    currentPassword: 'текущий пароль',
    newPassword1: 'Новый пароль',
    confirmPassword: 'Подтвердить Пароль',
    enterCurrentPassword: 'Введите текущий пароль',
    enterNewPassword: 'Введите новый пароль',
    confirmNewPassword: 'Подтвердите новый пароль',
    terminalNumber: 'Номер терминала',
    russian: 'Русский',
    english: 'Английский',
    hebrew: 'Иврит',
    editKitchen: 'Редактировать кухню',
    placeOrderMessage: 'Ваш заказ успешно размещен',
    orderDetails: 'Информация для заказа',
    confirmOrder: 'Вы уверены, что хотите подтвердить заказ',
    alertOrderMsg: 'Вы уже заказали!.',
    loginText: 'Войти',
    loginFormText: 'Давайте начнем',
    loginDesc: 'Войдите, чтобы продолжить на нашем сайте',
    remember: 'Запомните меня',
    addToOrder: 'Добавить в заказ',
    runOut: 'Закончиться',
    userStatus: 'Статус пользователь',
    UnavailableMealTypes: 'В настоящее время нет доступных типов приема пищи.',
    addMealType: 'Добавить тип питания',
    mealTypeId: 'Тип питания',
    image: 'Изображение',
    noExtrasDisplay: 'Нет дополнительных материалов для отображения',
    walletManagement: 'Управление кошельком',
    mealName: 'Название еды',
    credit: 'Кредит',
    walletDetails: 'Детали кошелька',
    insufficientWalletAlert:
      'Баланса вашего Кошелька недостаточно для выполнения этого заказа.',
    walletBalance: 'Баланс кошелька',
    cardId: 'Id карты',
    employeeId: 'Id сотрудника',
    copyUrl: 'Скопировать URL',

    mealTypeName: 'Тип према пищи',
    totalOfMeals: 'Всего приемов пищи',
    ofreservations: 'Резервация',
    perceReservedInventory: 'Зарезервировано/инвентарь, %',
    ofRealised: 'Осуществленный',
    ofRealisedInventory: 'Реализовано/Запасы, %',
    totalOfInventory: 'Общий инвентарь',

    detailedReservations: 'Подробности резервации',
    sortBy: 'Сортировать по',
    dishNameAscending: 'Название блюда (А-Я)',
    dishNameDescending: 'Название блюда(Я-А)',
    priceAscending: 'Цена (от самой высокой к самой низкой)',
    priceDescending: 'Цена (от самой низкой до самой высокой)',
    ratingAscending: 'Рейтинг (от высшего к низшему)',
    ratingDescending: 'Рейтинг (от самого низкого до самого высокого)',
    contractors: 'Группы',
    wallets: 'Кошельки',
    employee: 'Сотрудник',
    kitchens: 'Кухни',
    kitchen: 'Кухня',
    kitchenId: 'Кухня',
    totalPrice: 'Итоговая цена',
    review: 'просмотр',
    alreadyAdded: 'Уже добавлено',
    allMeal: 'Все блюда',
    reviewScore: 'Оценка по отзывам',
    SecondaryText: 'Вторичный текст',
    GotoControlPanel: 'Перейти к панели управления',
    Averagedailyreviewsscore: 'Средняя ежедневная оценка отзывов',
    home: 'Домой',
    placeHolderByDishSearch:
      'Поиск блюд по названию, категории, цене или рейтингу',
    added: 'Добавлен',
    reviewManagement: 'Управление отзывами',
    updateDish: 'Обновить блюдо',
    openSelectMenu: 'Откройте меню выбора',
    dishAddedMessage: 'Блюдо успешно добавлено',
    dishUpdatedMessage: 'Блюдо успешно обновлено',
    selectUserType: 'Выберите тип пользователя',
    admin: 'Администратор',
    siteManager: 'Модератор',
    kitchenManager: 'Менеджер кухни',
    kitchenWorker: 'Работник кухни',
    contractorEmployee: 'Сотрудник группы',
    contractorAdmin: 'Администратор группы',
    client: 'Клиент',
    userType: 'Тип пользователя',
    reviewTrend: 'Обзор тренда',
    termsAndCondition: 'Условия и положения',
    inv: 'Инв',
    ord: 'Зак-ов',
    rel: 'отн.',
    // New translations 08/02/22
    forgotSubHeading:
      'Заполните свой адрес электронной почты ниже, и мы вышлем вам электронное письмо с дальнейшими инструкциями.',
    forgotSubHeadingPhone:
      'Введите свой номер телефона ниже, и мы вышлем вам СМС с дальнейшими инструкциями.',
    poweredByWorkEat: 'powered by WorkEat',
    searchEmployeePH: 'Поиск сотрудника по имени, электронной почте или дате',
    setting: 'Параметры',
    searchForTagManagement: 'Поиск тегов по имени',
    updateTag: 'Обновить тег',
    kitchenDetail: 'Информация о кухне',
    logViewerDetails: 'Информация о юзере просмотревшем логи',
    previousMealDetail: 'Информация о предыдущем приеме пищи',
    ordered: 'Заказано',
    mealsDetailsTitle: 'Детали приема пищи',
    searchUserPH: 'Поиск пользователей по имени, электронной почте или дате',
    realizeDate: 'Дата выхода',
    totalsByUsers: 'Итоги резерваций по пользователям',
    mealTypeInReservation: 'Резервация по типу приема пищи',
    freeTextSearch: 'Введите текст для поиска',
    pending: 'В ожидании',
    success: 'Успешно',
    canceled: 'Отменено',
    reportType: 'Тип отчета',
    invoice: 'Счет',
    isInvoice: 'Счет',
    guestUsers: 'Гость',
    updateGuestUser: 'Обновить гостевого пользователя',
    addGuestUser: 'Добавить гостя',
    guestUserManagement: 'Управление гостевыми пользователями',
    startDate: 'Дата начала',
    endDate: 'Дата окончания',
    hireFrom: 'Дата начала',
    hireTill: 'Дата окончания',
    past: 'прошлый',
    next: 'Следующий',
    meal: 'Прием пищи',
    noOrder: 'Нет еды для заказа',
    dateDay: 'Дата и день',
    selectDishes: 'Выбрать блюда',
    approve: 'Одобрить',
    nextLobbyMeals: 'Следующий прием пищи',
    pastLobbyMeals: 'Готовые блюда',
    noMeal: 'Нет приема пищи для копирования, поэтому выберите другой.',
    orderRealized: 'Заказ реализован',
    ofRealizedReserved: 'реализовано/зарезервировано, %',
    today: 'Сегодня',
    walletAmount: 'Количество',
    walletAmountWord: 'Количество',
    UpdateWalletAMount: 'Обновить сумму кошелька',
    updatedOn: 'Обновление',
    realizeError:
      'Прием пищи еще не начался - Вы хотите продолжить с оплатой или отменить?',
    notRealized: 'Не реализовано',
    realizedMethod: 'Способ реализации',
    realizedByUserName: 'Реализовано по имени пользователя',
    realizedByDispenserName: 'Реализовано автоматом выдачи, Название',
    realizedByDispenserNameReport: 'Реализовано автоматом выдачи',
    resend: 'Отправить повторно',
    collectPoints: 'Собрать',
    confirmCollectPointMessage2: 'баллов за',
    pointMsg: 'кредитных баллов за',
    pointMsgEnd:
      'был успешно записан, баллы будут добавлены в кошелек в течение 24 часов на основании политики компании',
    alertNoMealsAbleMessage: 'Прием пищи недоступен!.',
    successMealTypePointMessage: ' баллов успешно набрано',
    errorMealTypePointMessage: 'Вы уже собрали баллы за это блюдо.',
    approvedPointMsg: 'Одобрено',
    is_include_sallary_file: 'Включить файл зарплаты',
    is_calculate_Price: 'Рассчитать стоимость блюда',
    updateMealType: 'Обновить тип питания',
    revertCollectPointMessage: 'Хотите отменить запрос на сбор',
    successReturnMealTypePointMessage: 'баллов успешно возвращено',
    pendingCollectedPoint: 'Кредитный балл',
    textManually: 'Вручную',
    pay: 'Оплатить',
    magneticStripe: 'Магнитная полоса',
    cibus: 'Сибус',
    paid: 'Оплаченный',
    paymentSuccessMessage: 'Платеж прошел успешно.',
    paymentFailureMessage: 'Извините! Ошибка оплаты',
    paymentOrderId: 'Идентификатор платежного поручения',
    notPaid: 'Не выплачивается',
    paymentStatus: 'Статус платежа',
    cibusPayment: 'Оплата сибусом',
    cibusPaymentAlert: 'Вы уверены, что хотите оплатить этот заказ?',
    UserName: 'Имя пользователя',
    isSalary: 'Зарплаты',
    isSalaryReport: 'Зарплаты',
    isfile: 'Имя файла',
    isCreatedAt: 'Создан в',
    isUpdatedAt: 'Обновлено',
    isDownload: 'Скачать',
    salaryFileReport: 'Отчет о зарплате',
    isEntries: 'Записи',
    getReservation: 'Получить резервацию',
    dropImage: 'Нажмите здесь или перетащите изображение',
    remove: 'Удалить',
    deleteMeal: 'Удалить прием пищи',
    companyId: 'Идентификатор компании',
    companyCard: 'Карточка компании',
    referenceId: 'Идентификатор ссылки',
    posId: 'Идентификатор торговой точки',
    applicationId: 'Идентификатор приложения',
    authorization: 'Авторизация',
    showReservation: 'Показать резервацию',
    resendMessage:
      'Письмо для сброса пароля было отправлено на электронную почту пользователя.',
    finish: 'Завершить',
    cibusError: 'Ошибка сибуса',
    codeNumber: '№ Кода',
    reservation: 'Резервация',
    manual: 'Руководство',
    qr: 'QR-код',
    card: 'Карта',
    isAproved: 'Одобрено',
    guestLink: 'Ссылка для гостя',
    generatedLink: 'Ссылка для гостя',
    enterQuantityFor: 'Введите количество для',
    forceSetPasswordDescription: `Срок действия вашего пароля истек - выберите новый пароль`,
    yes: 'Да',
    no: 'Нет',
    cancelledBy: 'Заказ отменен',
    cancelledOrderTime: 'Время отмены заказа',
    selectReservationStatus: 'Все статусы резервации',
    reservationStatus: 'статус резервации',
    totalCreditPointRequest: 'Общий запрос CP',
    pendingCreditPointRequest: 'Ожидающий запрос CP',
    approvedCreditPointReqquest: 'Утвержденный запрос CP',
    creditPointPercentage: 'Кредитный балл %',
    paymentType: 'Способ оплаты',
    paymentOptionId: 'Способ оплаты',
    selectPaymentType: 'Все типы платежей',
    selectReservationType: 'Все типы резерваций',
    displayName: 'Отображаемое имя',
    deviceType: 'Тип устройства',
    location: 'Расположение',
    createdAt: 'Создан в',
    updatedAt: 'Обновлено в',
    addKitchenDevice: 'Добавить кухонное устройство',
    kitchenDevices: 'Кухонные устройства',
    updateKitchenDevice: 'Обновить кухонное устройство',
    token: 'Токен',
    kitchenDevice: 'Кухонное устройство',
    vatFreePrice: 'Цена без НДС',
    addOrderUrl: 'Добавить URL-адрес заказа',
    paymentUrl: 'URL-адрес платежа',
    webAuth: 'WebAuth',
    oAuth: 'OAuth',
    host: 'URL хоста',
    languages: 'Языки',
    shortCode: 'Short Code',
    isRTL: 'Is RTL',
    languageStatus: 'Статус языка',
    addLanguage: 'Добавить язык',
    updateLanguage: 'Обновить язык',
    language: 'Язык',
    notEdit: 'Не изменяемо',
    copyToken: 'Скопировать токен',
    downloadFile:
      'Ваш файл скачивается в фоновом режиме, пожалуйста, не закрывайте сайт',
    cancellOrder: 'Отмена заказа произошла успешно',
    BeerShevaconveniencestoreRealized:
      'Магазин шаговой доступности в Beer Sheva реализовано',
    BeerShevaconveniencestoreReservation:
      'Магазин шаговой доступности в Beer Sheva забронировано',
    LunchCafeteriaRealized: 'Обеденный кафетерий реализовано',
    LunchCafeteriaReservation: 'Обеденный кафетерий забронировано',
    MorningCafeteriaRealized: 'Утренний кафетерий реализовано',
    MorningCafeteriaReservation: 'Утренний кафетерий забронировано',
    userId: 'ID пользователя',
    createdDate: 'Дата создания',
    Refusal_by_credit_company: 'Refusal by credit company',
    enterNonEmptyPassword: 'Введите текущий пароль',
    FileInpEmpty: 'Файл не был загружен',
    TitleEmpty: 'Заголовок не может быть пустым',
    removeForeignContractor:
      'Нельзя удалить эту группу так как она относится к другому администратору группы',
    attendance: 'Посещаемость',
    attendanceTitle: 'Посещаемость',
    entry: 'Вход',
    exit: 'Выход',
    all: 'Все',
    deleteAttachedFile: 'Вы уверены, что хотите удалить прикреплённый файл?',
    attachedFile: 'Прикреплённый файл',
    multipleReservations: 'Мульти резервация',
    fileTitle: 'Заголовок файла',
    titleTooLarge: 'макс. 20 символов',
    fileDeleted: 'Файл был удалён',
    orderedAndRealized:
      'На выбранную дату и время заказ уже был сделан и реализован',
    orderedMeal:
      'На выбранную дату и время заказ уже был сделан. Вы хотите изменить свой заказ?',
    selectKitchen: 'Выберите кухню',
    deleted: 'Удалённый',
    created: 'Созданный',
    selectUserStatus: 'Выберите статус пользователя',
    backOrEdit:
      'Вы хотите продолжить добавление продуктов в корзину или завершить бронирование?',
    checkout: 'Завершить',
    paymentNotMatch:
      'В этом аккаунте нет платежных реквизитов. Платеж должен быть завершен и использован снова.',
    generateSalaryText:
      'Выберите даты, которые необходимо включить в файл заработной платы:',
    dateRange: 'Диапазон дат',
    salaryGenerated: 'Файл заработной платы успешно составлен',
    reservationByUser: 'Резервация по пользователям',
    reservationByMeal: 'Резервация по блюду',
    search: 'Поиск',
    copyMeals: 'Копирование блюд',
    copyMealFromTitle: 'Выберите даты, c которых вы хотите скопировать блюда',
    copyMealToTitle: 'На какие даты копировать блюда с ',
    copyMealFromText: 'Скопировать блюда с',
    copy: 'Копировать',
    copyMealSuccess: 'Копирование блюд успешно завершено',
    copyMealStarted:
      'Процесс копирования начался и может занять до нескольких минут - вы можете продолжить использование системы',
    dateRangeNotMatch:
      'В выбранном диапазоне дат количество дней не соответствует количеству дней на предыдущем шаге',
    dateRangesIntersect: 'Диапазоны дат пересекаются',
    to: 'на',
    reservationType: 'Тип резервации',
    copyPastDateTitle: 'Копирование на прошлое',
    copyPastText:
      'Вы уверены, что хотите скопировать блюда на прошедший диапазон дат?',
    deleteMealTypeConfirmation:
      'Вы уверены, что хотите удалить этот тип блюда?',
    selectMealTypes: 'Выберите тип приёма пищи',
    allMealTypes: 'Все типы приёма пищи',
    specificMealTypes: 'Выбрать специальные типы приёма пищи',
    mealsNotSelect: 'Блюда не выбраны',
    noPastDates: 'Нет прошедших блюд',
    relatedMealTypes: 'Связанные типы блюд',
    totalByMeals: 'отчёт по блюдам',
    totalByUsers: 'отчёт по блюдам',
    totalByReservations: 'отчёт по резервациям',
    dishCategory: 'Категории блюд',
    system: 'Система',
    generatedBy: 'Сгенерировано',
    activate: 'Активировать',
    emplyeeID: 'Id сщтрудника',
    cardID: 'Id карты',
    activatedSuccessfully: 'Активация прошла успешно',
    activateUserConf: 'Вы уверены, что хотите активировать этого пользователя?',
    deleteUserConf: 'Вы уверены, что хотите удалить этого пользователя?',
    dateRangeTooBig: 'Диапазон дат слишком большой',
    donePointMsg: 'Готовы',
    cancelPointSuccess1: 'Ваш запрос на отмену ',
    cancelPointSuccess2: 'кредитных баллов за',
    cancelPointSuccess3: 'был успешно обработан',
    fieldIsRequired: 'Это поле обязательно',
    collectPointsMess: 'баллов за',
    doYouWant: 'Вы хотите получить',
    factory: 'Фабрика',
    factoryName: 'Имя фабрики',
    factoryKitchens: 'Кухни фабрики',
    addFactory: 'Добавить фабрику',
    updateFactory: 'Обновление фабрики',
    deleteFactory: 'Удаление фабрики',
    factoryManagement: 'Управление фабриками',
    deleteFactoryConfMess: 'Вы уверены, что хотите удалить фабрику?',
    factoryDetails: 'Детали фабрики',
    cibusRedirectUrl: 'Адрес перехода для сибуса',
    mainInfo: 'Персональная информация',
    endLessStart: 'Конечная дата не может быть меньше начальной',
    total: 'Итого',
    withoutKitchens:
      'Похоже, что Вы не назначены ни на одну кухню, пожалуйста, свяжитесь с Вашим администратором',
    mealStatus: 'Блюда',
    previousMealsStatus: 'Предыдущие блюда',
    futureMealsStatus: 'Будущие блюда',
    allMealsStatus: 'Все блюда',
    visitorCountry: 'Страна',
    visitPurpose: 'Цель визита',
    guestId: 'ID гостя',
    event: 'Событие',
    meeting: 'Встреча',
    conference: 'Конференция',
    technician: 'Техническая',
    isMultiOrders: 'Разрешить несколько резерваций',
    userCreateSuccessOne: 'Пользователь',
    userCreateSuccessTwo: 'был успешно создан',
    userUpdateSuccessTwo: 'был успешно обновлён',
    showUserCard: 'Показать карточку пользователя',
    createAnotherUser: 'Создать другого пользователя',

    inviteUser: 'Пригласить пользователя',
    updateAndApprove: 'Обновить и принять',
    updateInvite: 'Обновление приглашение',
    createInvite: 'Создание приглашения',
    reject: 'Отклонить',
    guestUserInvitation: 'Приглашение гостевого пользователя',
    inviteManagement: 'Управление приглашениями',
    invitedBy: 'Приглашён',
    createdBy: 'Создан',
    invitedDate: 'Дата приглашения',
    approvedBy: 'Принят',
    approvedAt: 'Дата утверждения',
    rejectedBy: 'Отклонён',
    rejectedAt: 'Дата отклонения',
    invitationStatus: 'Статус приглашения',
    guestInvitationStatusId: 'Статус приглашения',
    inviteDetails: 'Детали приглашения',
    selectInviteStatus: 'Выберите статус приглашения',
    invitedMessage: 'Приглашение было успешно сформировано',
    updateAndInvite: 'Обновление и подтверждение пользователя прошло успешно',
    approveSuccess: 'Пользователь был успешно подтверждён',
    rejectSuccess: 'Пользователь был успешно отклонён',
    invitationId: 'ID приглашения',
    unauthorized: 'Неавторизованный пользователь',
    tokenExpired: 'Ваш токен истёк. Попробуйте открыть ресурс заново',
    viewer: 'Наблюдатель',
    copied: 'Скопировано',
    confirmCollectPointMessage1: 'Ваш запрос о начислении',
    approveMessConfirm: 'Вы уверены, что хотите принять этого пользователя?',
    rejectMessConfirm: 'Вы уверены, что хотите отклонить пользователя?',

    mealDetails: 'Детали приёма пищи',
    tomorrow: 'Завтра',
    period: 'Период',
    forAllTime: 'За всё время',
    allStatuses: 'Все статусы',
    notOrdered: 'Не заказано',
    generalInfo: 'Основная информация',
    filters: 'Фильтры',
    dishQuantity: 'Количество блюд',
    availableMealsToOrder: 'Доступные блюда для заказа в',
    availableMealsToOrderShort: 'Доступные блюда для заказа',
    'dish-es': 'Блюд(а)',
    iWantToReceivePushNotifications: 'Я хочу получать push-уведомления',
    title: 'Заголовок',
    message: 'Сообщение',
    param: 'Параметры',
    notificationsDetails: 'Детали уведомления',
    notifications: 'Уведомления',
    users: 'Пользователь',
    connectteam: 'Connecteam',
    readStatus: 'Статус',
    read: 'Прочитано',
    unread: 'Не прочитано',
    readAll: 'Прочитать все',
    reset: 'Сбросить',
    numberSymbol: '#',
    invitationInfo: 'Информация о приглашении',
    invitation: 'Приглашение',
    alreadyApproved: 'уже одобрено',
    alreadyRejected: 'уже отклонено',
    deleteKitchenConfMess: 'Вы уверены, что хотите удалить эту кухню?',
    deleteKitchenSuccess: 'Удаление кухни прошло успешно',
    updateKitchenSuccess: 'Обновление кухни прошло успешно',
    addKitchenSuccess: 'Добавление кухни прошло успешно',
    res: 'Зак-ов',
    '7days': '7 дней',
    '6days': '6 дней',
    '5days': '5 дней',
    '1day': '1 день',
    weekView: 'Блюда на неделю',
    dishDescription: 'Описание блюда',
    factories: 'Фабрика',
    dayOfWeek: 'День недели',
    dishParam: 'Параметры блюда',
    userWithoutWallet:
      'Похоже, есть проблема с вашим кошельком, ваш запрос не был выполнен',
    contactYourAdmin: 'Обратитесь к администратору за помощью',
    dishWeekView: 'Блюдо',

    contractorsIds: 'Группы',
    kitchensIds: 'Кухни',
    roleId: 'Роль',
    userStatusId: 'Статус пользователя',
    oldValue: 'Старое значение',
    newValue: 'Новое значение',
    tagsHistory: 'История тега',
    updated: 'Обновление',
    history: 'История',
    namehe: 'Имя на ивр',
    descriptionhe: 'Описание на ивр',
    nameen: 'Имя на англ',
    descriptionen: 'Описание на англ',
    nameru: 'Имя на рус',
    descriptionru: 'Описание на рус',
    emptyHistoryMess: 'Нет доступных записей',
    tags: 'Тэги',
    orderWasPaid: 'Заказ был успешно оплачен',
    redirectToCibusSite: 'Перейти на сайт Сибуса',
    payByOneTimeCode: 'Оплатить по одноразовому коду',
    enterOneTimeCode: 'Пожалуйста, введите ваш одноразовый код',
    realizedUserName: 'Реализовано',
    cancelledDate: 'Дата отмены',
    orderStatusId: 'Статус',
    enterValidOneTimeCode: 'Введите правильный одноразовый код',
    deal_id: 'Id сделки',
    kitchenDeviceId: 'ID кухонного устройства',

    mealHistory: 'История блюда',
    isChefMealDish: 'Блюдо от шефа',
    dishId: 'Имя блюда',
    mealDishStatusId: 'Статус блюда',
    chargeTerminalId: 'ID терминала',
    separateFiles: 'Отдельные файлы',
    reportsHistory: 'История скачивания отчётов',
    transactionHistory: 'История транзакций',
    forReports: 'для отчётов',
    downloadInvoice: 'Загрузить чек',
    linkToInvoice: 'Ссылка на чек',
    pleaseSelectDate: 'Пожалуйста выберите дату',
    personalQRCode: 'Персональный QR код',
    missingDish: 'Отсутствуют блюда в заказе',

    fillRequiredFields: 'Пожалуйста заполните все обязательные поля',
    userIdCibus: 'ID пользователя (в Сибусе)',
    changePaymentStatus: 'Изменение статуса оплаты',
    changePaymentStatusMess: 'Вы уверены, что хотите изменить статус оплаты с',
    fromPendingToPaid: 'ожидание на оплачен',
    fromPaidToCancel: 'оплачен на возвращён',
    howOrderWasPaid: 'Пожалуйста выберите как заказ был оплачен',
    orderPaidBySite: 'Заказ был оплачен через сайт Сибуса',
    orderPaidByOtherMethods:
      'заказ был оплачен другим способом (через одноразовый код, магнитную полосу и т.д.)',
    paymentDetailsWasUpdated: 'Детали оплаты были успешно обновлены',
    somethingWentWrong: 'Что-то пошло не так. Пожалуйста попробуйте позже',
    editPaymentDetails: 'Редактировать детали оплаты',
    paymentOrderMessage: 'Сообщение платежного поручения',

    notes: 'Заметки',
    promote: 'Предолжение',
    pleaseUpdateMealParam: 'Пожалуйста обновите параметры блюда',
    updateMealParam: 'Обновление параметров блюда',
    updateAdditionalParam: 'Детали блюда',

    titlehe: 'כותרת',
    titleen: 'Title',
    titleru: 'Заголовок',
    descriptionHE: 'תיאור',
    descriptionEN: 'Description',
    descriptionRU: 'Описание',
    notesen: 'Notes',
    noteshe: 'הערות',
    notesru: 'Записи',
    linkToPaymentReceipt: 'Налогоывый документ',
    linkToCancellingReceipt: 'Налоговый документ об отмене',
    byPhone: 'С помощью телефона',
    byEmail: 'С помощью емэйла',
    areYouSure: 'Вы уерены, что хотите отменить эти резервации:',
    checkReservationNumber: 'Пожалуйста проверьте заказ-ы номер',
    selectOrders: 'Пожалуйста выберите заказы',
    realizedVoucher: 'Реализованный ваучер',
    bulkReservationCancellationTitle: 'Множественная отмена заказов',
    bulkReservationCancellationDesc: 'Вы уверены, что хотите отменить',
    bulkReservationCancellationErr:
      'Невозможно отменить выбранные заказы, пожалуйста проверьте таблицу с дополнительной информацией ниже',
    bulkReservationCancellationTitleFailed:
      'Невозможно отменить выбранные заказы',
    reason: 'Причина',
    unknownError: 'неизвестная ошибка',
    orderAlreadyCancelled: 'Заказ уже отменён',
    orderAlreadyReported: 'Заказ уже отмечен в файле зарплаты',

    switchToUserView: 'Переключиться на пользовательский вид',
    switchToManagerView: 'Переключиться на вид менеджера',
    additionalRole: 'Вторая роль',
    managerView: 'Вид менеджера',
    clientView: 'Вид клиента',

    promoteText: 'Текст предложения',
    promoteColor: 'Цвет предложения',
    promoteTextColor: 'Цвет текста предложения',
    additionalParams: 'Дополнительные параметры',
  },
};

export default translations;
