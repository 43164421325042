import action from '../action.js';
import types from './types';
import appTypes from '../app/types';
import kitchenTypes from '../kitchens/types';
import Http from '../../services/http';
import Storage from '../../services/storage';
import { socket } from '../../services/socket';
/**
 * Save Login Credentials to sessionStorage
 */
async function saveCredentialsToStorage(user) {
  await Storage.set('@technotest:api/user', JSON.stringify(user));
  localStorage.setItem('userId', user?.id);
}

export function getUser() {
  return action(async (dispatch) => {
    const token = await Storage.get('token');
    if (token) {
      Http.setAuthorizationHeader(token);
      let user = await Http.get('users/current');
      dispatch({ type: types.GET_USER, payload: user });
      return user;
    } else {
      return null;
    }
  });
}

export function register(payload) {
  return action(async (dispatch) => {
    let user = await Http.post('users/register', payload);
    return await setUser(user, dispatch);
  });
}

export function connectTeamLoginAction(data, languageId) {
  return action(async (dispatch) => {
    let language = '';
    if (languageId === 1) {
      language = 'he';
    }
    if (languageId === 2) {
      language = 'en';
    }
    if (languageId === 3) {
      language = 'ru';
    }
    localStorage.setItem('languageId', languageId);
    localStorage.setItem('language', JSON.stringify(language));

    //if (user.roleId === 4) {
    let userKitchen = await Http.get(`users/getCurrentKitchen/${data?.id}`);

    //let kitchenId = localStorage.getItem('kitchen');
    if (userKitchen?.lastUsedKitchenId) {
      localStorage.setItem('kitchen', userKitchen?.lastUsedKitchenId);
    } else {
      localStorage.setItem('kitchen', userKitchen?.KitchenId);
    }
    //}

    if (data.isPasswordTemporary) {
      return data;
    }
    return await setUser(data, dispatch, true);
  });
}
export function login(identifier, password, languageId, keepLoggedIn = true) {
  return action(async (dispatch) => {
    let language = '';
    if (languageId === 1) {
      language = 'he';
    }
    if (languageId === 2) {
      language = 'en';
    }
    if (languageId === 3) {
      language = 'ru';
    }
    let user = await Http.post(`users/authenticate`, { identifier, password });

    //if (user.roleId === 4) {
    let userKitchen = await Http.get(`users/getCurrentKitchen/${user?.id}`);
    //let kitchenId = localStorage.getItem('kitchen');
    if (userKitchen?.lastUsedKitchenId) {
      localStorage.setItem('kitchen', userKitchen?.lastUsedKitchenId);
    } else {
      localStorage.setItem('kitchen', userKitchen?.KitchenId);
    }
    //}

    if (user.isPasswordTemporary) {
      return user;
    }
    return await setUser(user, dispatch, keepLoggedIn);
  });
}

export function loginByRefreshToken(token, languageId, keepLoggedIn = true) {
  return action(async (dispatch) => {
    let language = '';
    if (languageId === 1) {
      language = 'he';
    }
    if (languageId === 2) {
      language = 'en';
    }
    if (languageId === 3) {
      language = 'ru';
    }
    let user = await Http.post(`users/authByRefreshToken`, { token });

    //if (user.roleId === 4) {
    let userKitchen = await Http.get(`users/getCurrentKitchen/${user?.id}`);
    //let kitchenId = localStorage.getItem('kitchen');
    if (userKitchen?.lastUsedKitchenId) {
      localStorage.setItem('kitchen', userKitchen?.lastUsedKitchenId);
    } else {
      localStorage.setItem('kitchen', userKitchen?.KitchenId);
    }
    //}

    if (user.isPasswordTemporary) {
      return user;
    }
    return await setUser(user, dispatch, keepLoggedIn);
  });
}

const setUser = async (user, dispatch, keepLoggedIn = true) => {
  if (keepLoggedIn) {
    await Storage.set('token', user.token);
    await saveCredentialsToStorage(user);
  }
  Http.setAuthorizationHeader(user.token);
  dispatch({ type: types.SET_USER, payload: user });
  return user;
};

export function sendOtp(identifier, phoneNumber) {
  return action(async (dispatch) => {
    await Http.post(`users/otp/email`, { identifier, phoneNumber });
    return;
  });
}

export function verifyOtp(
  identifier,
  phoneNumber,
  otpCode,
  keepLoggedIn = true
) {
  return action(async (dispatch) => {
    const user = await Http.post(`users/otp/verify`, {
      identifier,
      phoneNumber,
      otpCode,
    });
    return await setUser(user, dispatch, keepLoggedIn);
  });
}

/**
 * Logout
 */
export function logout(callServer = true, id, roleId) {
  return action(async (dispatch) => {
    console.log('roleId', roleId);

    if (roleId === 2 || roleId === 3 || roleId === 7) {
      await Http.put('users/changeActiveRole', {
        id: id,
        isActiveMainRole: true,
      });
    }
    if (callServer) {
      await Http.get('logout');
      window.location.href = window.location.origin + '/#/login';
    }
    localStorage.setItem('notificationsSocket', false);
    socket.emit('user_logout');
    localStorage.removeItem('showEmptyKitchenMess');
    localStorage.removeItem('switchToUserView');
    await removeCredentialsFromStorage();
    await Storage.remove('token');
    await Storage.remove('@technotest:api/user');
    await Storage.remove('userId');
    await Storage.remove('kitchen');
    await Storage.remove('isReloadingLobby');
    // await Storage.remove('languageId');
    // await Storage.remove('language');
    document.cookie.split(';').forEach((c) => {
      document.cookie = c
        .replace(/^ +/, '')
        .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
    });

    dispatch({ type: types.RESET });
    dispatch({ type: kitchenTypes.GET_KITCHENS, payload: {} });
    dispatch({
      type: kitchenTypes.GET_EMPLOYEE_LOBBY_DATA_NEW,
      payload: { data: [], total: 0 },
    });
  });
}

/**
 * Remove Login Credentials from sessionStorage
 */
async function removeCredentialsFromStorage() {
  await sessionStorage.removeItem('@workEat:api/user');
}

export function resetPassword(token, password) {
  return action(async (dispatch) => {
    let user = await Http.post(`reset/${token}`, { password });
    await saveCredentialsToStorage(user);
    dispatch({ type: types.SET_USER, payload: user });
    return user;
  });
}

export function update(userObj, saveToServer = true, showLoader = true) {
  return action(async (dispatch) => {
    if (saveToServer) {
      let updatedUser = await Http.put(`users`, userObj);
      // await saveCredentialsToStorage(updatedUser.results);
      // dispatch({type: types.SET_USER, payload: updatedUser.results})
      let user = await Http.get(`users/${userObj?.id}`);
      return dispatch({ type: types.GET_USER, payload: user });
      // await saveCredentialsToStorage(userObj);
      // dispatch({type: types.SET_USER, payload: userObj})
    } else {
      return dispatch({ type: types.SET_USER, payload: userObj });
    }
  }, showLoader);
  // }, showLoader)
}

export function getAllUser(param) {
  return action(async (dispatch) => {
    const objParam = {
      page: param.page,
      limit: param.limit,
      orderField: param?.orderField,
      orderTarget: param?.orderTarget,
      search: param?.search,
      companyId: param?.companyId ?? 1,
      contractorId: param?.contractorId,
      kitchenId: param?.kitchenId,
      roleId: param?.roleId,
      statusId: param?.statusId,
      invitedById: param?.invitedById,
    };
    for (const key in objParam) {
      if (
        objParam[key] === undefined ||
        objParam[key] === null ||
        objParam[key] === 'null'
      ) {
        delete objParam[key];
      }
    }
    let data = await Http.get('users', objParam);
    dispatch({ type: appTypes.TOGGLE_FETCHING_LOADER, payload: true });
    dispatch({ type: types.GET_ALL_USERS, payload: data });
    dispatch({ type: appTypes.TOGGLE_FETCHING_LOADER, payload: false });
  });
}

export function getAllUserData(filter) {
  return action(async (dispatch) => {
    let data = await Http.get('users', { filter });
    dispatch({ type: appTypes.TOGGLE_FETCHING_LOADER, payload: true });
    dispatch({ type: types.GET_ALL_USER_DATA, payload: data });
    dispatch({ type: appTypes.TOGGLE_FETCHING_LOADER, payload: false });
  });
}
