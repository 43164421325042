import React, { useState, useEffect, useRef } from 'react';
import { Modal, Box, Grid, styled, Typography, Paper } from '@material-ui/core';
import Select from 'react-select';
import { ActiveButton } from '../../components/WE-Button';
import { Dialog } from '../../components/Dialogs/SwalDialogs';
import {
  validateEmployee,
  validateGuestUsersNew,
} from '../../services/validators';
import cancel from '../../assets/images/close.svg';
import { VariableSizeList as List } from 'react-window';
import moment from 'moment';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #ededed',
  boxShadow: 24,
  padding: '15px',
  p: 4,
  borderRadius: '5px',
};

const GetInputField = ({
  value,
  placeholder,
  cb,
  type,
  label,
  name,
  required,
}) => {
  return (
    <>
      <label>{label}</label>
      <input
        className={`${placeholder}`}
        name={name}
        placeholder={placeholder}
        defaultValue={value}
        onBlur={(event) => cb(event.target.value)}
        required
        // disabled={!editMode}
        type={type}
      />
    </>
  );
};

const AddGuestUserPopup = ({
  open,
  handleClose,
  handleAddNewUser,
  Roles,
  Kitchens,
  UserStatuses,
  setPayload,
  payload,
  handleUpdateUser,
  setIsEdit,
  isUpdate,
  userData,
  setKitchensIds,
  kitchensIds,
  t,
  errors,
  setErrors,
  user,
  contractorList,
  kitchen,
  getMealTypes,
  mealTypesIds,
  setMealTypesIds,
  setGetMealTypes,
  currentUserKitchens,
  setMealTypesKitchenWise,
  getMealTypesKitchenWise,
  countryOptions,
  visitPurposeValues,
  isMultiOrders,
  setIsMultiOrders,
  allUsersForInvite,
}) => {
  const KitchensMap = Kitchens?.reduce((acc, kitchen) => {
    return { ...acc, [kitchen.id]: kitchen.name };
  }, {});
  const MealTypesMap = getMealTypes?.reduce((acc, mealType) => {
    return { ...acc, [mealType.id]: mealType.display };
  }, {});

  const RolesMap_ValueId = Roles.reduce((acc, role) => {
    return { ...acc, [role.value]: role.id };
  }, {});

  const ContractorMap_ValueId = contractorList?.reduce((acc, contractor) => {
    return { ...acc, [contractor.id]: contractor.name };
  }, {});
  const StatusMap = UserStatuses.reduce((acc, status) => {
    return { ...acc, [status.id]: status.display };
  }, {});

  const [role] = useState(4);
  const [pError, setPError] = useState(false);
  const [status, setStatus] = useState({ value: 3, label: 'Active' });
  function useResetCache(data) {
    const ref = useRef(null);
    useEffect(() => {
      if (ref.current != null) {
        ref.current.resetAfterIndex(0, true);
      }
    }, [data]);
    return ref;
  }
  const height = 40;
  const MenuList = ({
    options,
    children,
    maxHeight,
    width,
    getValue,
    selectProps,
  }) => {
    const objects = document.querySelectorAll('.input-field');
    const elementsArray = Array.from(objects);
    const otherDropdownsWidth = elementsArray[4].offsetWidth - 26;
    const [value] = getValue();

    const canvas = document.getElementById('canvas');
    const ctx = canvas.getContext('2d');
    ctx.font = '16px Open Sans';

    const getItemSize = (index) => {
      let colRows = children[index]?.props
        ? Math.ceil(
            ctx.measureText(children[index].props.data.label).width /
              otherDropdownsWidth
          )
        : 1;
      colRows = colRows > 1 ? colRows * 0.75 : colRows;
      return 40 * colRows;
    };
    const gridRef = useResetCache(children.length);
    return (
      <List
        height={
          children.length
            ? children.length < 5
              ? children.reduce((acc, curr) => {
                  let colRows = curr?.props
                    ? Math.ceil(
                        Math.round(
                          ctx.measureText(
                            Array.isArray(curr.props.data.label)
                              ? curr.props.data.label[0].trim()
                              : curr.props.data.label.trim()
                          ).width
                        ) /
                          (children.length < 5
                            ? otherDropdownsWidth + 6
                            : otherDropdownsWidth)
                      )
                    : 1;
                  colRows = colRows > 1 ? colRows * 0.75 : colRows;
                  return acc + 44 * colRows;
                }, 0)
              : 200
            : 0
        }
        ref={gridRef}
        itemCount={children.length}
        itemSize={
          children.length
            ? getItemSize
            : () => {
                return 0;
              }
        }
        className="customList"
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    );
  };

  function capitalizeEachWord(string) {
    if (string) {
      const words = string.toLowerCase().split(' ');
      const capitalizedWords = words.map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      });
      return capitalizedWords.join(' ');
    } else {
      return '';
    }
  }
  useEffect(() => {
    setPayload({
      ...payload,
      contractorsIds: payload?.contractors?.map((contractor) => contractor.id),
    });
  }, [payload?.contractors]);
  useEffect(() => {
    if (
      payload?.allContractors &&
      Array.isArray(payload?.allContractors) &&
      (!payload?.contractorsIds || payload?.contractorsIds.length === 0) &&
      payload?.allContractors.length === 1
    ) {
      const elem = payload?.allContractors[0];
      setPayload((prev) => {
        return {
          ...prev,
          contractorsIds: [elem?.id],
          magneticStripe: elem?.magneticStripe ?? '',
        };
      });
    }
    if (isUpdate && payload.mealTypesIds && payload.mealTypesIds.length !== 0) {
      setPayload((prev) => {
        const result = (
          typeof prev?.mealTypesIds === 'string'
            ? JSON.parse(prev?.mealTypesIds)
            : prev?.mealTypesIds
        ).filter((elem) => MealTypesMap[elem] !== undefined);
        return { ...prev, mealTypesIds: result };
      });
    }
    if (
      currentUserKitchens &&
      Array.isArray(currentUserKitchens) &&
      (!payload?.kitchensIds || payload?.kitchensIds.length === 0) &&
      getMealTypes &&
      currentUserKitchens.length === 1
    ) {
      setKitchensIds([currentUserKitchens[0].id]);
      setPayload((prev) => {
        return {
          ...prev,
          kitchensIds: [Kitchens[0].id],
        };
      });
      let newMealTypeDateArray = [];
      if (getMealTypes && Array.isArray(getMealTypes)) {
        let newMealTypeInfo = getMealTypes?.filter(
          (elInfo) => elInfo?.kitchenId === currentUserKitchens[0]?.id
        );
        newMealTypeDateArray = [...newMealTypeDateArray, ...newMealTypeInfo];
        setMealTypesKitchenWise(newMealTypeDateArray);
        if (newMealTypeDateArray.length === 1) {
          setMealTypesIds([Number(newMealTypeDateArray[0].id)]);
          setPayload((prev) => {
            return {
              ...prev,
              mealTypesIds: [Number(newMealTypeDateArray[0].id)],
              mealTypeId: [Number(newMealTypeDateArray[0].id)],
            };
          });
        }
      }
    }
  }, [currentUserKitchens, payload?.allContractors, open]);

  const handleChange = (name, value) => {
    if (name === 'isMultiOrders') {
      setIsMultiOrders(!isMultiOrders);
      value = !isMultiOrders;
    }
    console.log('name', name);
    console.log('value', value);

    const { isValid, error } = validateEmployee(value, name, 'guest');
    setErrors({ ...errors, ...error });
    setPayload((prev) => ({ ...prev, [name]: value }));
  };
  const SelectStyles = {
    control: (provided, state) => ({
      ...provided,
      height: 'auto',
      minHeight: 40,
      backgroundColor: 'transparent',
      border: '1px solid #e3e3e3',
      boxShadow: 'none',

      fontSize: '16px',
      fontFamily: 'OpenSans',
      borderRadius: '5px',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      width: 200,
      fontSize: '16px',
      padding: '3px 10px',
      fontFamily: 'OpenSans',
    }),
    option: (provided, state) => ({
      padding: '10px',
      width: '100%',
      fontSize: '16px',
      fontFamily: 'OpenSans',

      backgroundColor:
        state.isFocused && !state.isSelected
          ? '#cce9ff'
          : state.isSelected
            ? '#2589f5'
            : 'transparent',
      color: state.isSelected ? '#ffffff' : '#000000',
      cursor: state.isFocused ? 'pointer' : 'auto',
    }),
  };
  useEffect(() => {
    if (open && isUpdate === false && currentUserKitchens && getMealTypes) {
      let newMealTypeDateArray = [];
      let newMealTypeInfo = getMealTypes?.filter(
        (elInfo) => elInfo?.kitchenId === currentUserKitchens[0].id
      );

      newMealTypeDateArray = [...newMealTypeDateArray, ...newMealTypeInfo];
      console.log('newMealTypeDateArray', newMealTypeDateArray[0].id);

      setPayload({
        ...payload,
        kitchensIds: [currentUserKitchens[0]?.id],
        mealTypesIds: [newMealTypeDateArray[0]?.id],
        contractorsIds: [
          payload?.allContractors
            ? payload?.allContractors[0]?.id
            : contractorList[0]?.id,
        ],
        invitedBy: [allUsersForInvite[0]?.value],
      });
      handleChange('hireFrom', moment().format('YYYY-MM-DD'));
      handleChange('hireTill', moment().add(7, 'days').format('YYYY-MM-DD'));
      setKitchensIds([currentUserKitchens[0].id]);
      setMealTypesKitchenWise(newMealTypeDateArray);
      setMealTypesIds([newMealTypeDateArray[0].id]);
    }
  }, [open]);
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    let details = {
      firstName: payload.fName,
      lastName: payload.lName,
      email: payload?.email ? payload?.email : null,
      phone: payload?.phone,
      userStatusId: { value: 3, label: 'Active' },
      kitchensIds: userData
        .find((el) => el.id === payload.userId)
        ?.kitchens.map((ele) => ele.id),
      password: payload.password === '' ? undefined : payload.password,
      cardId: payload.card,
      employeeId: payload.employeeId,
      roleId: RolesMap_ValueId.guestUser,
      kitchensIds,
      magneticStripe: payload.magneticStripe,
      contractorsIds: payload.contractorsIds,
      hireFrom: payload.hireFrom,
      hireTill: payload.hireTill,
      mealTypeId: payload?.mealTypesIds ?? [],
      company: payload?.company,

      visitorCountry: payload?.visitorCountry
        ? Array.isArray(payload?.visitorCountry)
          ? payload?.visitorCountry[0]
          : payload?.visitorCountry
        : '',
      visitPurpose: payload?.visitPurpose
        ? Array.isArray(payload?.visitPurpose)
          ? payload?.visitPurpose[0]
          : payload?.visitPurpose
        : '',
      guestId: payload?.guestId,
      isMultiOrders: payload?.isMultiOrders,
      invitedBy: payload?.invitedBy,
    };
    const { isValid, errors } = validateGuestUsersNew(details);
    setErrors(errors);
    if (isValid) {
      await handleAddNewUser({ ...details });
      // handleClose();
    }
  };
  const CheckPassword = (value) => {
    if (
      value.label === 'Active' &&
      (payload.password === '' || !payload.password)
    ) {
      /*setPError(true);
      // setErrors({ ...errors, password: 'Password is required' });*/
      setPayload({ ...payload, status: 'Active' });
    } else {
      setPError(false);
      setStatus(value);
      setPayload({ ...payload, status: value.label });
      setErrors({ ...errors, password: '' });
    }
  };

  return (
    <Modal
      open={open}
      // onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      maxWidth="lg"
      className="add-employee-popup"
    >
      <Box sx={style}>
        <div>
          <div className="close-btn">
            <img src={cancel} alt="close" onClick={handleClose} />
          </div>
          <Typography
            id="modal-modal-title"
            variant="h5"
            component="h2"
            className="create-cont-emp-title"
          >
            {isUpdate ? t('updateGuestUser') : t('addGuestUser')}
          </Typography>
        </div>
        <Box id="modal-modal-description" className="width-add-user">
          <form className="add-user-form">
            <div className="user-create-form item user-create-form-adaptivity">
              <div className="user-create-block-wrapper">
                <p className="user-create-title">{t('mainInfo')}</p>
                <div className="user-create-block">
                  <div className="input-field w-75">
                    <GetInputField
                      placeholder={t('firstName')}
                      label={t('firstName') + ' *'}
                      name="fName"
                      type="text"
                      value={payload['fName']}
                      cb={(value) => {
                        handleChange('fName', value);
                      }}
                    />
                    {errors && (
                      <p className="error-msg">
                        {errors.fName || errors.firstName}
                      </p>
                    )}
                  </div>
                  <div className="input-field w-75">
                    <GetInputField
                      placeholder={t('lastName')}
                      label={t('lastName') + ' *'}
                      name="lName"
                      type="text"
                      value={payload['lName']}
                      cb={(value) => {
                        handleChange('lName', value);
                      }}
                      required
                    />
                    {errors && (
                      <p className="error-msg">
                        {errors.lName || errors.lastName}
                      </p>
                    )}
                  </div>
                  <div className="input-field w-75">
                    <GetInputField
                      placeholder={t('phone')}
                      label={t('phone')}
                      name="phone"
                      type="number"
                      value={payload['phone']}
                      cb={(value) => {
                        handleChange('phone', value);
                      }}
                      required
                    />
                    {errors && (
                      <p className="error-msg">
                        {errors.phone || errors.phone}
                      </p>
                    )}
                  </div>

                  <div className="input-field w-75">
                    <GetInputField
                      placeholder={t('email')}
                      label={t('email')}
                      name="email"
                      type="email"
                      value={payload['email']}
                      cb={(value) => {
                        handleChange('email', value);
                      }}
                      required
                    />
                    {errors?.email && (
                      <p className="error-msg">{errors.email}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="line"></div>
              {/* <div className="input-field w-75">
                <GetInputField
                  placeholder={t('password')}
                  label={t('password')}
                  name="password"
                  type="password"
                  value={payload['password']}
                  cb={(value) => {
                    handleChange('password', value);
                  }}
                  required
                />
                {pError && <p className="error-msg">Password is required</p>}
                {errors && <p className="error-msg">{errors.password}</p>}
              </div> */}
              <div className="user-create-block-wrapper">
                <p className="user-create-title">{t('Settings')}</p>
                <div className="user-create-block">
                  <div className="input-field w-75">
                    <label>{t('kitchenname') + ' *'}</label>
                    <Select
                      isMulti
                      name={'kitchensIds'}
                      components={{ MenuList }}
                      value={kitchensIds?.map((kitchenId) => {
                        return {
                          value: kitchenId,
                          label: KitchensMap[kitchenId],
                        };
                      })}
                      onChange={(newData) => {
                        setPayload({
                          ...payload,
                          kitchensIds: newData.map((k) => k.value),
                        });
                        let newMealTypeDateArray = [];
                        newData?.forEach((elData) => {
                          let newMealTypeInfo = getMealTypes?.filter(
                            (elInfo) => elInfo?.kitchenId === elData?.value
                          );
                          newMealTypeDateArray = [
                            ...newMealTypeDateArray,
                            ...newMealTypeInfo,
                          ];
                        });
                        setMealTypesKitchenWise(newMealTypeDateArray);
                        if (newMealTypeDateArray.length === 1) {
                          setMealTypesIds([newMealTypeDateArray[0].value]);
                          setPayload({
                            ...payload,
                            mealTypesIds: [newMealTypeDateArray[0].id],
                          });
                        }
                        setKitchensIds(newData.map((k) => k.value));
                        if (
                          payload.mealTypesIds &&
                          payload.mealTypesIds.length > 0
                        ) {
                          let newMealTypeDateArrayInfo = [];
                          newMealTypeDateArray?.forEach((elDataInfo) => {
                            let newMealTypeInfo1 = payload.mealTypesIds?.filter(
                              (elInfoID) => elInfoID === elDataInfo?.id
                            );
                            newMealTypeDateArrayInfo = [
                              ...newMealTypeDateArrayInfo,
                              ...newMealTypeInfo1,
                            ];
                          });
                          setPayload({
                            ...payload,
                            mealTypesIds: newMealTypeDateArrayInfo,
                          });
                        }
                      }}
                      options={currentUserKitchens.map((kitchen) => {
                        return { value: kitchen.id, label: kitchen.name };
                      })}
                      placeholder={t('kitchenname')}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 2147483647 }),
                        ...SelectStyles,
                      }}
                      required
                    />
                    {errors && (
                      <p className="error-msg">{errors.kitchensIds}</p>
                    )}
                  </div>
                  <div className="input-field w-75">
                    <label>{t('mealType')}</label>
                    <Select
                      isMulti
                      components={{ MenuList }}
                      name={'mealTypeId'}
                      // value={mealTypesIds?.map((el) => {
                      //   return { value: el, label: MealTypesMap[el] };
                      // })}
                      value={(typeof payload?.mealTypesIds === 'string'
                        ? JSON.parse(payload?.mealTypesIds)
                        : payload?.mealTypesIds
                      )?.map((el) => {
                        return { value: el, label: MealTypesMap[el] };
                      })}
                      onChange={(newData) => {
                        setMealTypesIds(newData.map((k) => k.value));
                        setPayload({
                          ...payload,
                          mealTypesIds: newData.map((k) => k.value),
                        });
                      }}
                      // options={getMealTypes?.map((el) => {
                      //   return { value: el.id, label: el.display };
                      // })}
                      options={getMealTypesKitchenWise?.map((el) => {
                        return { value: el.id, label: el.display };
                      })}
                      placeholder={t('mealType')}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 2147483647 }),
                        ...SelectStyles,
                      }}
                      required
                    />
                    {errors && <p className="error-msg">{errors.mealTypeId}</p>}
                  </div>
                  <div className="input-field w-75">
                    <label>{t('contractor') + ' *'}</label>
                    <Select
                      name={'contractorsIds'}
                      components={{ MenuList }}
                      value={
                        payload.contractorsIds &&
                        payload.contractorsIds.map((elem) => {
                          return {
                            value: elem,
                            label: ContractorMap_ValueId[elem],
                            disableDelete:
                              payload?.allContractors &&
                              payload?.contractors &&
                              !Boolean(
                                payload?.allContractors.find(
                                  (el) => el.id === elem
                                ) &&
                                  payload?.contractors.find(
                                    (el) => el.id === elem
                                  )
                              ),
                          };
                        })
                      }
                      onChange={(newData, triggeredAction) => {
                        if (
                          triggeredAction.action === 'remove-value' &&
                          triggeredAction.removedValue.disableDelete
                        ) {
                          Dialog({
                            title: t('removeForeignContractor'),
                            type: 'error',
                            buttons: {
                              showCancelButton: false,
                              confirmButtonText: t('confirm'),
                            },
                          });
                          return;
                        } else {
                          setPayload({
                            ...payload,
                            contractorsIds: [newData.value],
                            magneticStripe:
                              contractorList.find(
                                (el) => el.id === newData.value
                              )?.magneticStripe ?? '',
                          });
                        }
                      }}
                      options={
                        payload?.allContractors
                          ? payload?.allContractors?.map((contractors) => {
                              return {
                                value: contractors.id,
                                label: [contractors.name],
                                disableDelete: payload?.contractors
                                  ? payload?.contractors.length === 0
                                    ? false
                                    : !payload?.contractors.find(
                                        (el) =>
                                          el.label ===
                                          ContractorMap_ValueId[contractors.id]
                                      )
                                  : false,
                              };
                            })
                          : contractorList?.map((contractors) => {
                              return {
                                value: contractors.id,
                                label: [contractors.name],
                                disableDelete: payload?.contractors
                                  ? payload?.contractors.length === 0
                                    ? false
                                    : !payload?.contractors.find(
                                        (el) =>
                                          el.label ===
                                          ContractorMap_ValueId[contractors.id]
                                      )
                                  : false,
                              };
                            })
                      }
                      placeholder={t('contractor')}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 2147483647 }),
                        ...SelectStyles,
                      }}
                      required
                    />
                    {errors && (
                      <p className="error-msg">{errors.contractorsIds}</p>
                    )}
                  </div>
                  {/* <div className="input-field w-75">
                <label>{t('kitchenname')}</label>
                <Select
                  isMulti
                  name={'kitchensIds'}
                  value={kitchensIds?.map((kitchenId) => {
                    return { value: kitchenId, label: KitchensMap[kitchenId] };
                  })}
                  onChange={(newData) => {
                    setKitchensIds(newData.map((k) => k.value));
                    setPayload({
                      ...payload,
                      kitchensIds: newData.map((k) => k.value),
                    });
                  }}
                  options={Kitchens.map((kitchen) => {
                    return { value: kitchen.id, label: kitchen.name };
                  })}
                  placeholder={t('kitchenname')}
                  styles={SelectStyles}
                  required
                />
              </div> */}

                  {/* <div className="input-field w-75">
                <label>{t('contractor')}</label>
                <Select
                  // isMulti
                  name={'contractorId'}
                  defaultValue={{
                    value: payload?.contractor,
                    label:
                      ContractorMap_ValueId &&
                      ContractorMap_ValueId[payload?.contractor],
                  }}
                  onChange={(newData) => {
                    // setRolesIds(newData.value);
                    setPayload({ ...payload, contractorId: newData.value });
                  }}
                  options={contractorList?.map((contractors) => {
                    return {
                      value: contractors.id,
                      label: [contractors.name],
                    };
                  })}
                  placeholder={t('contractor')}
                  styles={SelectStyles}
                  required
                /> */}
                  {/* {errors.role && <p className="error-msg">Role is required</p>} */}
                  {/* </div> */}
                  <div className="input-field w-75">
                    <label>{t('invitedBy') + ' *'}</label>
                    <Select
                      isClearable
                      name="invitedBy"
                      components={{ MenuList }}
                      value={allUsersForInvite.find(
                        (el) => el.value == payload?.invitedBy
                      )}
                      onChange={(newData) => {
                        setPayload({
                          ...payload,
                          invitedBy: newData?.value ? [newData?.value] : '',
                        });
                      }}
                      options={
                        allUsersForInvite
                          ? allUsersForInvite.map((el) => ({
                              value: el.value,
                              label: capitalizeEachWord(el.label),
                            }))
                          : []
                      }
                      placeholder={t('invitedBy')}
                      isSearchable={true}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 2147483647 }),
                        ...SelectStyles,
                      }}
                    />
                    {errors && <p className="error-msg">{errors.invitedBy}</p>}
                  </div>
                  <div className="input-field w-75">
                    <GetInputField
                      placeholder={t('startDate')}
                      label={t('startDate') + ' *'}
                      name="hireFrom"
                      type="date"
                      value={payload['hireFrom']}
                      cb={(value) => {
                        handleChange('hireFrom', value);
                      }}
                      required
                    />
                    {errors && <p className="error-msg">{errors.hireFrom}</p>}
                  </div>
                  <div className="input-field w-75">
                    <GetInputField
                      placeholder={t('endDatee')}
                      label={t('endDate') + ' *'}
                      name="hireTill"
                      type="date"
                      value={payload['hireTill']}
                      cb={(value) => {
                        handleChange('hireTill', value);
                      }}
                      required
                    />
                    {errors && (
                      <p className="error-msg">{t(errors.hireTill)}</p>
                    )}
                  </div>
                  {user.roleId === 1 || isUpdate ? (
                    <div className="input-field w-75">
                      <label>{t('status') + ' *'}</label>

                      <Select
                        name={'status'}
                        components={{ MenuList }}
                        defaultValue={
                          UserStatuses.find(
                            (el) => el.display === payload.status
                          )
                            ? {
                                value: UserStatuses.find(
                                  (el) => el.display === payload.status
                                )?.id,
                                label: UserStatuses.find(
                                  (el) => el.display === payload.status
                                )?.display,
                              }
                            : { value: 1, label: StatusMap[1] }
                        }
                        onChange={(newData) => {
                          CheckPassword(newData);
                        }}
                        options={UserStatuses.map((kitchen) => {
                          return { value: kitchen.id, label: kitchen.display };
                        })}
                        placeholder={t('status')}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({
                            ...base,
                            zIndex: 2147483647,
                          }),
                          ...SelectStyles,
                        }}
                        // isMulti
                        required
                      />
                    </div>
                  ) : (
                    ''
                  )}
                  <div className="input-field w-75">
                    <GetInputField
                      placeholder={t('guestId')}
                      label={t('guestId')}
                      name="guestId"
                      type="string"
                      value={payload['guestId']}
                      cb={(value) => {
                        handleChange('guestId', value);
                      }}
                      editMode={true}
                    />
                    {errors && <p className="error-msg">{errors.guestId}</p>}
                  </div>

                  <div className="input-field w-75">
                    <GetInputField
                      placeholder={t('company')}
                      label={t('company')}
                      name="Company"
                      type="string"
                      value={payload['company']}
                      cb={(value) => {
                        handleChange('company', value);
                      }}
                      editMode={true}
                    />
                    {errors && <p className="error-msg">{errors.company}</p>}
                  </div>

                  <div className="input-field w-75">
                    <label>{t('visitorCountry')}</label>
                    <Select
                      name={'visitorCountry'}
                      components={{ MenuList }}
                      value={countryOptions.find(
                        (el) =>
                          el.value == payload?.visitorCountry ||
                          el.label == payload?.visitorCountry
                      )}
                      isClearable
                      onChange={(newData) => {
                        setPayload({
                          ...payload,
                          visitorCountry: [newData?.label],
                        });
                      }}
                      options={countryOptions}
                      placeholder={t('visitorCountry')}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 2147483647 }),
                        ...SelectStyles,
                      }}
                    />
                    {errors && (
                      <p className="error-msg">{errors.visitorCountry}</p>
                    )}
                  </div>

                  <div className="input-field w-75">
                    <label>{t('visitPurpose')}</label>
                    <Select
                      name={'visitPurpose'}
                      components={{ MenuList }}
                      value={visitPurposeValues.find(
                        (el) =>
                          el.value == payload?.visitPurpose ||
                          el.label == payload?.visitPurpose
                      )}
                      isClearable
                      onChange={(newData) => {
                        setPayload({
                          ...payload,
                          visitPurpose: [newData?.value],
                        });
                      }}
                      options={visitPurposeValues}
                      placeholder={t('visitPurpose')}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 2147483647 }),
                        ...SelectStyles,
                      }}
                    />
                    {errors && (
                      <p className="error-msg">{errors.visitPurpose}</p>
                    )}
                  </div>
                  <div className="input-field type-checkbox add-user-checkbox">
                    <input
                      type="checkbox"
                      checked={payload['isMultiOrders']}
                      name="isMultiOrders"
                      value={payload['isMultiOrders']}
                      onClick={(value) => {
                        handleChange('isMultiOrders', value);
                      }}
                    />
                    <label>{t('isMultiOrders')}</label>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <canvas id="canvas" width="0" height="0"></canvas>
          {isUpdate ? (
            <div className="add-employee-btn">
              <div onClick={handleUpdateUser}>
                <ActiveButton label={t('update')} arrow={false} />
              </div>
            </div>
          ) : (
            <div className="add-employee-btn">
              <div onClick={handleFormSubmit}>
                <ActiveButton label={t('add')} arrow={false} />
              </div>
            </div>
          )}
        </Box>
      </Box>
    </Modal>
  );
};
export default AddGuestUserPopup;
