import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { Button, Form } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import LoginBanner from './loginBanner';
import GuestScreen from '../Guests/GuestScreen';
import { ActiveButton } from '../../components/WE-Button';
import logo from '../../assets/logo/workeat-logo.svg';
import CompanyLogo from '../../assets/logo/logo-gamla.svg';
//import CompanySecondaryLogo from '../../assets/logo/WD-SecondoryLogo.svg';
import { connect } from 'react-redux';
import LogoWE from '../../assets/logo/logo-we.png';
import http from '../../services/http';
import * as SwalDialogs from '../../components/Dialogs/SwalDialogs';
import backImg from '../../assets/images/eva_arrow-back-outline.svg';
import phoneImg from '../../assets/images/icons/phone-call-icon.svg';
import Swal from 'sweetalert2';
import {
  Alert,
  SuccessDailog,
  Dialog,
  DialogLogin,
} from '../../components/Dialogs/SwalDialogs';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const EmailValidator = (value) =>
  value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);
const phoneValidator = (value) => {
  const PhoneRegexp = /^(?:\+?972|0)(?:[23489]|5[0-8])\d{7}$/;
  return PhoneRegexp.test(value);
};
window.recaptchaOptions = {
  useRecaptchaNet: true,
};

const Forget = ({ companyLogo }) => {
  let { t } = useTranslation();
  const [value, setValue] = useState();
  const [phoneValue, setPhoneValue] = useState();
  const [number, setNumber] = useState();
  const [countryCode, setCountryCode] = useState();
  const [captcha, setReCaptcha] = useState(false);
  const [recaptchaResponse, setRecaptchaResponse] = useState();
  const [error, setError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [recoveryMethod, setRecoveryMethod] = useState('email');
  const handlereCaptcha = (value) => {
    if (value) {
      setReCaptcha(true);
      setRecaptchaResponse(value);
    }
  };

  const handleForgetPasswordClick = async () => {
    history.push('/forget');
  };
  const history = useHistory();
  const handleResetPassword = async () => {
    setError(false);
    setPhoneError(false);
    if (!EmailValidator(value) && recoveryMethod === 'email') {
      setError(true);
    } else if (!phoneValidator(phoneValue) && recoveryMethod === 'phone') {
      setPhoneError(true);
    } else {
      let res = await http
        .post(`users/resetPassword`, {
          email: value,
          phone: '+' + phoneValue,
          countryCode: countryCode,
          rawPhoneNumber: number,
          recoveryType: recoveryMethod,
          recaptchaResponse,
          companyId: localStorage.getItem('companyId'),
        })
        .then((response) => {
          return Dialog({
            title: t('resetPasswordTitle'),
            text: t(
              recoveryMethod === 'phone'
                ? 'resetPasswordTextPhone'
                : 'resetPasswordText'
            ),
            type: 'success',
            buttons: {
              showCancelButton: false,
              confirmButtonText: t('confirm'),
            },
            onConfirm: () => {
              // setOpen(false);
              grecaptchaObject?.reset();
              setValue('');
              setError('');
            },
          });
        })
        .catch((error) => {
          Dialog({
            title: error,
            type: 'error',
            buttons: {
              showCancelButton: false,
              confirmButtonText: t('confirm'),
            },
            onConfirm: () => {
              grecaptchaObject?.reset();
              setValue('');
              setError('');
            },
          });
        });
    }
  };

  const handleChange = (e) => {
    setError(!EmailValidator(e.target.value));
    setValue(e.target.value);
  };
  const handleChangePhone = (e) => {
    setPhoneError(!phoneValidator(e.target.value));
    setPhoneValue(e.target.value);
  };
  const grecaptchaObject = window.grecaptcha;

  const handlePhoneChange = (value, data) => {
    setCountryCode(`+${data.dialCode}`);
    setNumber(value.replace(`${data.dialCode}`, ''));
    setPhoneValue(value);
  };
  return (
    <>
      <Helmet>
        <body className="login-page-padding"></body>
      </Helmet>
      <GuestScreen
        content={
          <>
            <div className="login forget-password-page">
              <div className="reset-page-header">
                {/* <div className="inner-header-flex mb-header">
                  <img src={LogoWE} alt="" style={{ width: '30px',}} />
                  <img src={CompanyLogo} alt="" style={{ width: '40px',}} />
                </div> */}
                <div className="inner-header-flex">
                  <Button
                    onClick={() => {
                      history.push(`/login`);
                    }}
                    className="back-btn"
                  >
                    <img src={backImg} alt="icon" />
                    {t('back')}
                  </Button>
                  <a className="back-btn phone-btn" href="tel:+91023456789">
                    <img src={phoneImg} alt="icon" />
                    +91 023 456 789
                  </a>
                </div>
              </div>
              <div className="form">
                <div className="logo">
                  {/* <h6>{t('welcomeText')}</h6> */}
                  <img src={logo} alt="logo" />
                  <img
                    src={companyLogo}
                    alt=""
                    style={{ width: '50px', margin: '0 15px' }}
                  />
                  {/* <img
                    src={CompanySecondaryLogo}
                    alt=""
                    className="comapany-secondary-logo"
                  /> */}
                </div>
                <div className="login-heading">
                  <h2>{t('forgotPassword')}</h2>
                  <p>
                    {t(
                      recoveryMethod === 'email'
                        ? 'forgotSubHeading'
                        : 'forgotSubHeadingPhone'
                    )}
                  </p>
                </div>
                <div className="inputs-wrapper">
                  <Form>
                    <div className="green-radio">
                      <div className="green-radio-elem">
                        <Form.Check
                          type="radio"
                          id="email"
                          name="customRadio"
                          label={t('byEmail')}
                          className="mb-2 d-flex gap-3 align-items-center"
                          value="email"
                          checked={recoveryMethod === 'email'}
                          onChange={(e) => {
                            setRecoveryMethod(e.target.defaultValue);
                          }}
                        />
                      </div>
                      <div className="green-radio-elem">
                        <Form.Check
                          type="radio"
                          id="tel"
                          name="customRadio"
                          label={t('byPhone')}
                          className="mb-2 d-flex gap-3 align-items-center"
                          value="phone"
                          checked={recoveryMethod === 'phone'}
                          onChange={(e) => {
                            setRecoveryMethod(e.target.defaultValue);
                          }}
                        />
                      </div>
                    </div>
                  </Form>
                  {recoveryMethod === 'email' ? (
                    <Form.Group
                      className="form-group"
                      controlId="formBasicPassword"
                    >
                      <Form.Label>{t('email')}</Form.Label>
                      <Form.Control
                        name="email"
                        placeholder={t('email')}
                        // defaultValue={value}
                        onBlur={(e) => handleChange(e)}
                        onChange={(e) => setValue(e.target.value)}
                        required={true}
                        type="text"
                        value={value}
                      />
                      <i class="las la-envelope"></i>
                    </Form.Group>
                  ) : (
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'start',
                      }}
                    >
                      <div style={{ maxWidth: '300px' }}>
                        <PhoneInput
                          country={'il'}
                          value={phoneValue}
                          onChange={handlePhoneChange}
                          inputStyle={{
                            width: '100%',
                          }}
                          dropdownStyle={{
                            maxHeight: '150px',
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {error && <p className="error-msg">{t('invalidEmail')}</p>}
                  {phoneError && (
                    <p className="error-msg">{t('invalidPhoneNumber')}</p>
                  )}
                  <div className="recaptcha">
                    <ReCAPTCHA
                      theme="light"
                      sitekey="6LeDMxkfAAAAAEhBNa8zcj4DuuPbyfIWuwG2uAqO"
                      onChange={handlereCaptcha}
                      className="recaptcha"
                      grecaptchaObject={grecaptchaObject}
                    />
                  </div>
                  <div className="flex-column new-design-btn">
                    <ActiveButton
                      type="submit"
                      additionalClassName={'btn'}
                      label={t('send')}
                      cb={handleResetPassword}
                    />
                  </div>
                </div>
              </div>
              <div className="align-right privacy-text mob-view-privacy">
                {!localStorage.getItem('token') ? (
                  <>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                    <Link to="/terms-condition"> Terms & Conditions</Link>
                  </>
                ) : (
                  ''
                )}
              </div>
              <LoginBanner />
            </div>
          </>
        }
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  companyLogo: state.companyConfig.companyLogo,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Forget);
